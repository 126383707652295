
import {defineComponent, Ref} from "vue";
import {LocalDb} from "@/repository/localdb";
import {CollectionResponse} from "@/models/collection_models";
import {UserService} from "@/services/user_service";
import {UserBalanceData} from "@/models/stored_data_models";

export default defineComponent({
  name: "RailwayBookingPaymentSelect",
  data() {
    return {
      balance: {} as Ref<UserBalanceData>,
      paymentTypes: null as any,
      selectedPaymentType: null as any,
      totalCost: 0,
      amountFromUserBalance: null as any,
    };
  },
  created() {
    const collection: CollectionResponse | null = LocalDb.getCollections();
    const {totalCost} = LocalDb.getMyRailwayOrder(
        this.$route.params.id.toString()
    );
    this.totalCost = totalCost;

    if (collection) {
      this.paymentTypes = collection.paymentTypes;
    }
  },

  async mounted() {
    this.balance = await UserService.getUserBalance();
  },

  computed: {
    isBalanceEnough(): boolean {
      if (this.selectedPaymentType === "Balance") {
        return this.balance.balanceAmount >= this.totalCost / 100;
      }
      return true;
    },
  },

  methods: {
    onChangeAmountFromUserBalance() {
      if (isNaN(+this.amountFromUserBalance)) {
        this.amountFromUserBalance = "";
        return;
      }
      if (+this.amountFromUserBalance >= this.balance.balanceAmount) {
        this.amountFromUserBalance = this.balance.balanceAmount;
        return;
      }
      if (+this.amountFromUserBalance >= this.totalCost / 100) {
        this.amountFromUserBalance = this.totalCost / 100;
        return;
      }
    },
  },

  watch: {
    selectedPaymentType: {
      handler: function (after, before) {
        const data = {
          selectedPaymentType: this.selectedPaymentType,
          amountFromUserBalance: this.amountFromUserBalance,
        };
        if (!this.isBalanceEnough) {
          data.selectedPaymentType = "notEnoughInBalance";
          this.$emit("onPaymentTypeChangeEvent", data);
          return;
        }
        this.$emit("onPaymentTypeChangeEvent", data);
      },
      deep: true,
    },

    amountFromUserBalance: {
      handler: function (after, before) {
        const data = {
          selectedPaymentType: this.selectedPaymentType,
          amountFromUserBalance: this.amountFromUserBalance,
        };
        this.$emit("onPaymentTypeChangeEvent", data);
      },
      deep: true,
    },
  },
});
