
import {defineComponent} from "vue";
import TheHeader from "@/components/TheHeader.vue";
import NavigatorLine from "@/components/NavigatorLine.vue";
import TheFooter from "@/components/TheFooter.vue";
import RailwayBookingSteps from "@/components/RailwayBooking/Steps.vue";
import RailwayPaymentExtraInfo from "@/components/RailwayPayment/ExtraInfo.vue";
import RailwayBookingTariff from "@/components/RailwayBooking/RailwayTariff.vue";
import RailwayBookingContactInfo from "@/components/RailwayBooking/ContactInfo.vue";
import RailwayBookingPassengerList from "@/components/RailwayBooking/PassengerList.vue";
import RailwayBookingPaymentSelect from "@/components/RailwayBooking/PaymentSelect.vue";
import FlightBookingPaymentPriceChange from "@/components/FlightBooking/PaymentPriceChange.vue";
import Loader from "@/components/General/Loader.vue";
import PaymentForm from "@/components/General/PaymentForm.vue";
import {Passenger} from "@/models/order_modes";
import {User} from "@/models/auth/user_models";
import {LocalDb} from "@/repository/localdb";
import {SearchParameter} from "@/models/search_parameters";
import {AvioneMessage} from "@/constants/error_messages";
import Swal from "sweetalert2";
import {FlightPaymentData} from "@/models/payment_models";
import {DetectData} from "@/constants/detect";
import {ResponseStatusType} from "@/constants/response_status_type";
import {PaymentService} from "@/services/payment_service";
import api from "@/services/api_request";
import moment from "moment";

export default defineComponent({
  name: "RailwayBookingPayment",
  components: {
    TheHeader,
    RailwayBookingSteps,
    NavigatorLine,
    RailwayPaymentExtraInfo,
    RailwayBookingTariff,
    RailwayBookingContactInfo,
    RailwayBookingPassengerList,
    RailwayBookingPaymentSelect,
    Loader,
    FlightBookingPaymentPriceChange,
    PaymentForm,
    TheFooter,
  },
  setup() {
    const {doOrder} = DetectData;
    return {
      doOrder,
    };
  },
  data() {
    return {
      validate: false,
      loading: false,
      loadingMessage: "",
      isLoginOpen: false,
      priceChangeShow: false,
      openPaymentPopup: false,
      paymentUrl: "",
      currentUser: {} as User | null,
      passengers: [] as Passenger[],
      paymentData: {} as FlightPaymentData,
      paymentType: null,
      amountFromUserBalance: 0,
      // ticketBooking: {} as TicketBooking,
      orderData: {} as any | undefined,
      searchParameter: {} as SearchParameter | null,
      selectedTickets: [],
      carInfo: {} as any,
      trainRes: {} as any,
      orderEndTimeSeconds: "" as any,
    };
  },

  created() {
    this.currentUser = LocalDb.getCurrentUser();
    this.searchParameter = LocalDb.getSearchParameters();

    const orderId = this.$route.params.id;
    if (orderId) {
      const order: any | undefined = LocalDb.getMyRailwayOrder(
        orderId.toString()
      );

      if (order) {
        this.orderData = order;
        this.passengers = order.passengers;

        this.selectedTickets = order.trainDetails?.forward[0]?.tickets.map(
          (ticket: any) => ticket.seats
        );
      }
      // else {
      //   window.location.href = '/';
      // }
    }
  },
  async mounted() {
    this.loading = true;
    const data = {
      stationFrom: this.orderData?.trainDetails?.forward[0]?.departure
        ?.stationCode,
      stationTo: this.orderData?.trainDetails?.forward[0]?.arrival?.stationCode,
      direction: [
        {
          depDate: this.orderData?.trainDetails?.forward[0]?.arrival?.localDate,
          fullDay: true,
          type: "Forward",
          train: {
            number: this.orderData?.trainDetails?.forward[0]?.arrival?.train,
          },
        },
      ],
    };
    try {
      const {place} = await api.post("/railway/trains-type/", data, {
        headers: {Authorization: "bearer " + LocalDb.getToken()?.token},
      });
      this.trainRes = place?.direction[0]?.trains[0]?.train;
    } catch (error) {
      console.log(error);
    }

    if (this.orderData?.orderStatus === "B") {
      try {
        const {endTime} = await api.post(
          "/railway/end-time/",
          {orderId: this.orderData?.orderId},
          {
            headers: {Authorization: "bearer " + LocalDb.getToken()?.token},
          }
        );
        this.orderEndTimeSeconds = endTime?.seconds;
      } catch (error) {
        console.log(error);
      }
    }
    this.loading = false;
    if (this.orderEndTimeSeconds > 0) {
      this.onDecreaseOrderEndTime();
    } else {
      this.orderEndTimeSeconds = 0;
    }
  },
  methods: {
    getFormattedEndTime: function () {
      return moment
        .utc(
          moment.duration(this.orderEndTimeSeconds, "seconds").asMilliseconds()
        )
        .format("mm:ss");
    },
    onChangeCarType: function (value: any) {
      this.carInfo = value;
    },
    onPaymentFormCloseEventHandler: function () {
      this.openPaymentPopup = false;
      this.paymentUrl = "";
      // if (this.orderData)
      //   window.location.href = "/flight/payment/confirm/" + this.orderData.orderId;
    },
    onContinuePriceChangeClickEventHandler: async function () {
      this.priceChangeShow = false;
      await this.onPaymentClickEventHandler();
    },
    onClosePriceChangeClickEvent: function () {
      this.priceChangeShow = false;
      this.$router.push({
        path: "/profile/orders",
        params: {
          lang: this.$route.params.lang,
        },
      });
    },
    onPaymentTypeChangeEventHandler: function (value: any) {
      this.paymentType = value.selectedPaymentType;
      this.amountFromUserBalance = value.amountFromUserBalance * 100;
    },

    onPaymentClickEventHandler: async function () {
      if (!this.paymentType) {
        await Swal.fire(
          "",
          this.$t(AvioneMessage.PAYMENT_TYPE_CHOOSE),
          "error"
        );
        return;
      }
      if (this.paymentType === "notEnoughInBalance") {
        await Swal.fire("", this.$t(AvioneMessage.NotEnoughInBalance), "error");
        return;
      }
      if (this.orderData == undefined) {
        await Swal.fire("", this.$t(AvioneMessage.ORDER_NOT_FOUND), "error");
        return;
      }
      this.loading = true;
      this.loadingMessage = this.$t(
        AvioneMessage.PAYMENT_TYPE_AVIPAY_REDIRECTION
      );

      const paymentData = {
        orderId: this.$route.params.id,
        amountFromUserBalance: this.amountFromUserBalance,
        requiredOnlinePayment:
          this.orderData?.totalCost - this.amountFromUserBalance,
        paymentMethod: this.paymentType,
      };

      if (this.paymentType === "Balance") {
        await PaymentService.getPaymentFromBalance({
          orderId: this.$route.params.id,
        }).then((response) => {
          this.loading = false;
          this.loadingMessage = "";
          if (response.status == "Ok") {
            window.location.href = "/profile/orders";
          } else if (response.status == 3) {
            this.priceChangeShow = true;
            this.paymentData = response.data;
            if (this.orderData) {
              this.orderData.amount = response.data.newPrice;
            } else {
              Swal.fire("Body error", response.data.message, "error");
            }
          } else {
            Swal.fire("Network error", response.message, "error");
          }
        });
      } else {
        await PaymentService.getPaymentRailway(paymentData).then((response) => {
          this.loading = false;
          this.loadingMessage = "";
          if (response.status == ResponseStatusType.Error) {
            if (response.data.success_url) {
              window.location.href = response.data.success_url;
            } else if (response.data.status == 3) {
              this.priceChangeShow = true;
              this.paymentData = response.data;
              if (this.orderData) {
                this.orderData.amount = response.data.newPrice;
              }
            } else {
              Swal.fire("Body error", response.data.message, "error");
            }
          } else {
            Swal.fire("Network error", response.message, "error");
          }
        });
      }
    },

    onDonwloadTicket: async function () {
      try {
        const data = await api.get(
          `/railway/ticket-file?orderId=${this.orderData?.orderId}`,
          {
            headers: {Authorization: "bearer " + LocalDb.getToken()?.token},
            responseType: 'blob',
          }
        );
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ticket.pdf"); // Set the desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
      }
    },
    onDecreaseOrderEndTime: function () {
      setInterval(() => {
        this.orderEndTimeSeconds--;
      }, 1000);
    },
    onCancelPaymentRequest: function () {
      LocalDb.removeBookingTicket();
      this.redirectToHome();
    },
    redirectToHome: function () {
      window.location.href = "/";
    },
  },
});
