
import {defineComponent, PropType} from "vue";
import {OrderData} from "@/models/stored_data_models";
import {FlightOrderStatus} from "@/models/flight_order_status";
import axios from "axios";

export default defineComponent({
  name: "FlightOrderPassengers",

  props: {
    order: {type: Object as PropType<OrderData>},
  },
  data() {
    return {
      showMoreDetails: false,
      paidStatus: FlightOrderStatus.PAID,
    };
  },
});
