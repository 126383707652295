
import {computed, defineComponent} from "vue";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import NavigatorLine from "@/components/NavigatorLine.vue";
import FlightBookingSteps from "@/components/FlightBooking/Steps.vue";
import FlightBookingDetail from "@/components/FlightBooking/Detail.vue";
import FlightBookingTariff from "@/components/FlightBooking/Tariff.vue";
import FlightBookingContactInfo from "@/components/FlightBooking/ContactInfo.vue";
import FlightBookingPassengerForm from "@/components/FlightBooking/PassengerForm.vue";
import FlightBookingExtraInfo from "@/components/FlightBooking/ExtraInfo.vue";
import FlightBookingResult from "@/components/FlightBooking/Result.vue";
import Alert from "@/components/General/Alert.vue";
import Loader from "@/components/General/Loader.vue";
import {
  OrderRequest,
  OrdersAddress,
  OrdersPhone,
  TicketBooking,
} from "@/models/order_modes";
import {OfferService} from "@/services/offer_service";
import {ResponseStatusType} from "@/constants/response_status_type";
import {User, Gender, Country} from "@/models/auth/user_models";
import {LocalDb} from "@/repository/localdb";
import {SearchParameter} from "@/models/search_parameters";
import {TicketOwner} from "@/constants/ticket_owner";
import {
  getCountryInfoByPhoneNumber,
  getPhoneNumberInfo,
} from "@/helpers/phone_number_helper";
import {AvioneMessage} from "@/constants/error_messages";
import Swal from "sweetalert2";
import {isStringNullOrEmpty} from "@/helpers/general_helper";
import flightBookingController from "@/composables/flight_booking";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {useHead} from "@vueuse/head";
import moment from "moment";

export default defineComponent({
  name: "FlightBooking",
  components: {
    TheHeader,
    FlightBookingSteps,
    NavigatorLine,
    FlightBookingDetail,
    FlightBookingTariff,
    FlightBookingContactInfo,
    FlightBookingPassengerForm,
    FlightBookingExtraInfo,
    Loader,
    Alert,
    FlightBookingResult,
    TheFooter,
  },

  setup() {
    const {state, generatePassengers} = flightBookingController();
    const {t} = useI18n({useScope: "global"});
    const route = useRoute();
    const router = useRouter();
    const currentUrl = computed(
        () =>
            router.resolve({
              name: "FlightBooking",
              params: {lang: route.params.lang},
            }).href
    );

    useHead({
      title: computed(() => t("pages.home.title")),
      meta: [
        {
          name: `description`,
          content: computed(() => t("pages.home.description")),
        },
        {
          name: `og:title`,
          content: computed(() => t("pages.home.title")),
        },
        {
          name: `og:description`,
          content: computed(() => t("pages.home.description")),
        },
        {
          name: `og:type`,
          content: "website",
        },
        {
          name: `og:url`,
          content: computed(() => `https://avione.uz${currentUrl.value}`),
        },
      ],

      link: computed(() =>
          [
            {
              rel: "canonical",
              href: `https://avione.uz${currentUrl.value}`,
            },
          ].concat(
              [
                {lang: "uz", prefix: "uz"},
                {lang: "en", prefix: "en"},
                {lang: "ru", prefix: ""},
              ]
                  .filter((l) => route.params.lang !== l.prefix)
                  .map((l) => {
                    return {
                      rel: "alternate",
                      hreflang: l.lang,
                      href:
                          "https://avione.uz" +
                          router.resolve({
                            name: "Home",
                            params: {lang: l.prefix},
                          }).href,
                    };
                  })
          )
      ),
    });
    return {...state, generatePassengers};
  },
  data() {
    return {
      validate: false,
      isLoginOpen: false,
      alertShow: false,
      alertMessage: "",
      bookingResultShow: false,
      orderNumber: "MP7SEA",
      currentUser: {} as User | null,
      searchParameter: {} as SearchParameter | null,
    };
  },

  async created() {
    const ticket: TicketBooking | null = LocalDb.getBookingTicket();

    if (ticket) {
      this.ticketBooking = ticket;
      this.currentUser = LocalDb.getCurrentUser();
      this.searchParameter = LocalDb.getSearchParameters();
      if (this.searchParameter)
        this.generatePassengers(this.searchParameter.selectedPassengers);
    } else {
      window.location.href = "/";
    }
  },
  methods: {
    onCancelBooking: function () {
      LocalDb.removeBookingTicket();
      this.$router.push({
        path: "/",
        params: {lang: this.$route.params.lang},
      });
    },

    onBookRequest: async function () {
      try {
        this.validateAll();
        const orderRequest: OrderRequest = this.collectBookingInfo();

        this.loading = true;
        this.loadingMessage = "Подождите, идет процесс бронирования...";
        await OfferService.makeOrder(orderRequest).then((response) => {
          this.loading = false;
          this.loadingMessage = "";
          if (response.status == ResponseStatusType.Ok) {
            this.bookingResultShow = true;
            this.orderNumber = response.data.pnrNumber;
            LocalDb.saveMyFlightOrder(response.data);
          } else {
            this.loading = false;
            Swal.fire(
                "",
                "Упс! Что-то пошло не так. Пожалуйста, попробуйте еще раз.",
                "error"
            );
          }
        });
      } catch (e) {
        this.loading = false;
        this.validate = true;
        await Swal.fire("", e.message, "warning");
      }
    },

    validateAll: function () {
      this.validate = false;
      this.bookingResultShow = false;
      this.validateTariff();
      this.validateBookingTicket();
      this.validateCurrentUser();
      this.validatePassenger();
    },

    validatePassenger: function () {
      for (const passenger of this.passengers) {
        if (isStringNullOrEmpty(passenger.firstName))
          throw new Error(this.$t(AvioneMessage.PASSENGER_FIRSTNAME_REQUIRED));
        if (isStringNullOrEmpty(passenger.lastName))
          throw new Error(this.$t(AvioneMessage.PASSENGER_LASTNAME_REQUIRED));
        if (isStringNullOrEmpty(passenger.birthDate?.toString()))
          throw new Error(this.$t(AvioneMessage.PASSENGER_BIRTHDATE_REQUIRED));
        if (isStringNullOrEmpty(passenger.phoneNumber))
          throw new Error(
              this.$t(AvioneMessage.PASSENGER_PHONENUMBER_REQUIRED)
          );
        if (!passenger.gender)
          throw new Error(this.$t(AvioneMessage.PASSENGER_GENDER_REQUIRED));
        if (!passenger.citizenship)
          throw new Error(
              this.$t(AvioneMessage.PASSENGER_CITIZENSHIP_REQUIRED)
          );
        // if (!passenger.email)
        //   throw new Error(this.$t(AvioneMessage.PASSENGER_EMAIL_REQUIRED));
        // if (!passenger.document.issue)
        //   throw new Error(this.$t(AvioneMessage.PASSENGER_ISSUE_REQUIRED));
        if (!passenger.document.expire)
          throw new Error(this.$t(AvioneMessage.PASSENGER_EXPIRE_REQUIRED));
        if (!passenger.document.type)
          throw new Error(
              this.$t(AvioneMessage.PASSENGER_DOCUMENTTYPE_REQUIRED)
          );
        if (passenger.document.expire && moment().diff(passenger.document.expire, "months") >= -3 && moment().diff(passenger.document.expire, "years") >= 0) {
          const {departure, arrival} = this.ticketBooking?.ticket || {}
          const airports = ['TAS', 'SKD', 'BHK', 'UGC', 'FEG', 'NCU', 'TMJ', 'NVI'] // these airports check passport expiry date

          if (!departure?.length && arrival?.departureAirport) {
            // one way ticket
            if (airports.includes(arrival?.departureAirport)) {
              // throw warning that passport of this passenger is almost expired and it would cause problem in these airports
              throw new Error(
                  this.$t(AvioneMessage.PASSPORT_EXPITE)
              )
            }
          } else if (departure?.length && departure?.[0]?.departureAirport) {
            // two way ticket
            if (airports.includes(departure?.[0]?.departureAirport)) {
              // throw warning that passport of this passenger is almost expired and it would cause problem in these airports
              throw new Error(
                  this.$t(AvioneMessage.PASSPORT_EXPITE)
              )
            }
          }
        }
        if (isStringNullOrEmpty(passenger.document.number))
          throw new Error(
              this.$t(AvioneMessage.PASSENGER_PASSPORNUMBER_REQUIRED)
          );
      }
    },

    validateBookingTicket: function () {
      if (this.ticketBooking == undefined)
        throw new Error(this.$t(AvioneMessage.TARIF_NOT_SELECTED));
    },

    validateTariff: function () {
      if (this.tariffs?.data?.fareFamily.length > 0) {
        if (this.tariffs && this.tariffs.status == ResponseStatusType.Ok) {
          if (
              this.selectedTariff === undefined ||
              isStringNullOrEmpty(this.selectedTariff?.segments)
          )
            throw new Error(this.$t(AvioneMessage.TARIF_NOT_SELECTED));
        }
      }
    },

    validateCurrentUser: function () {
      if (this.currentUser == null || this.currentUser === undefined)
        throw new Error(this.$t(AvioneMessage.CURRENT_USER_NOT_FOUND));
    },

    collectBookingInfo: function (): OrderRequest {
      if (this.currentUser == null || this.currentUser === undefined)
        throw new Error(this.$t(AvioneMessage.CURRENT_USER_NOT_FOUND));

      const orderPhone: OrdersPhone = {
        number: this.currentUser.phoneNumber,
        code: this.currentUser.phoneNumberInfo.code,
        extra: this.currentUser.extraPhoneNumber,
      };

      const address: OrdersAddress = {
        additional: this.currentUser.address,
        city: this.currentUser.city,
        zip: this.currentUser.zip,
        country: this.currentUser.country?.code,
      };

      const passengers = JSON.parse(JSON.stringify(this.passengers))

      for (let passenger of passengers) {
        if (passenger.phoneNumber) {
          passenger.phoneNumberInfo =
              getCountryInfoByPhoneNumber(passenger.phoneNumber, this) ?? undefined;
          passenger.phoneNumber = getPhoneNumberInfo(passenger.phoneNumber, this)
              .getNationalNumber()
              ?.toString();
        }
        if (passenger.birthDate)
          passenger.birthDate = new Date(
              passenger.birthDate
          ).toLocaleDateString("en-CA");
        if (passenger.document.issue)
          passenger.document.issue = new Date(
              passenger.document.issue
          ).toLocaleDateString("en-CA");
        if (passenger.document.expire)
          passenger.document.expire = new Date(
              passenger.document.expire
          ).toLocaleDateString("en-CA");
      }

      const orderRequest: OrderRequest = {
        buyId: this.getBuyId(),
        ticketOwner: this.ticketBooking.ticketOwner,
        phone: orderPhone,
        address: address,
        emails: [this.currentUser.email],
        passengers: passengers,
        flightDetails: {
          departure: this.ticketBooking?.ticket.departure,
          arrival: this.ticketBooking?.ticket.arrival,
        },
      };
      return orderRequest;
    },

    getBuyId: function (): string {
      if (
          this.selectedTariff != null &&
          !isStringNullOrEmpty(this.selectedTariff.segments)
      )
        return this.selectedTariff.segments;
      else if (this.ticketBooking) return this.ticketBooking.buyId;
      return "";
    },

    onOkClickEventHandler: function () {
      this.alertShow = false;
      this.alertMessage = "";
    },

    onClosePaymentClickEventHandler: function () {
      this.bookingResultShow = false;
      this.orderNumber = "";
      this.$router.push({
        path: "/",
        params: {
          lang: this.$route.params.lang,
        },
      });
    },

    onPaymentClickEventHandler: function () {
      this.$router.push({
        name: "FlightBookingPayment",
        params: {
          id: this.orderNumber,
          lang: this.$route.params.lang,
        },
      });
    },
  },
});
