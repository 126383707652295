<template>
  <div
      style="position: relative; height: 600px; overflow-x: scroll"
      class="p-4 sm:p-6 bg-white rounded-lg border border-opacity-70"
      :style="showOnly && 'height: 442px'">
    <div>
      <span class="font-semibold text-lg">{{
          !showOnly
              ? $t("RailwayTariff.ChooseCarriageAndSeat")
              : $t("RailwayTariff.ChoosenCarriages")
        }}</span>
    </div>
    <div
        v-if="!showOnly"
        style="display: grid; grid-template-columns: repeat(4, 1fr)"
        class="mt-1 font-semibold">
      <div
          v-for="(item, index) in trainResponse?.cars"
          :key="index"
          @click="kupe(index, item)"
          :class="[
          showKupe && index === index1 ? 'bg-k' : '',
          !showKupe && index === index1 ? 'bg-k' : '',
        ]"
          class="bg-white text-black cursor-pointer border p-4 rounded-lg mr-6">
        <span>{{ item?.type }}:</span>
        <span class="ml-2">
          {{ $t("RailwayTariff.Price") }}
          {{ getTotalPrice(item?.tariff, item?.comissionFee) }}
          {{ $t("Currency") }}</span>
      </div>
    </div>
    <div
        v-for="(item, index) in trainResponse?.cars"
        :key="index"
        class="mt-1 flex">
      <div
          v-for="(itemCar, indexCar) in item?.car"
          :key="indexCar"
          class="flex">
        <div
            v-if="!showOnly && item?.tariff === tariff"
            style="width: 150px"
            class="bg-white bg-hover-tarif mr-6 font-semibold border text-black p-4 rounded-lg mt-4"
            @click="clickBilet(itemCar, item)"
            :class="[
            showBilet && itemCar?.number === carNumber ? 'bg-c' : '',
            item?.type != itemType ? 'bg-kk' : '',
          ]">
          <div class="text-center">
            {{ $t("RailwayTariff.Carriage") }}{{ itemCar?.number }}
          </div>
        </div>
        <Cv
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></Cv>
        <Plascartny
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></Plascartny>
        <Kupe
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></Kupe>
        <BizClass
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></BizClass>
        <TotalLarge
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></TotalLarge>
        <TotalMini
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></TotalMini>
        <BizClass22
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></BizClass22>
        <BizClass26
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></BizClass26>
        <EcoClass36
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></EcoClass36>
        <VipClass11
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></VipClass11>
        <VipClass16
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></VipClass16>
        <EcoClass47
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></EcoClass47>
        <EcoClass39
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></EcoClass39>
        <EcoClass54
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></EcoClass54>
        <EcoClass49
            :itemCar="itemCar"
            :showBilet="showBilet"
            :carNumber="carNumber"
            :itemp="item"
            @selectedSeatsLength="selectedSeatsLength = $event"
            :showOnly="showOnly"
            :selectedSeats="selectedTickets"></EcoClass49>
      </div>
    </div>
    <div v-if="!showOnly" class="mt-6">
      <span class="font-normal text-lg">{{ $t("RailwayTariff.ChooseUpToFourSeat") }}</span>
    </div>
    <div class="mt-6" style="
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        position: absolute;
        bottom: 20px;
        gap: 10px
      ">
      <div>
        <div class="text-center">{{ $t("RailwayTariff.FreePlaces") }}</div>
        <div class="text-center text-lg font-semibold">
          {{ freePlaces }}
        </div>
      </div>
      <div>
        <div class="text-center">
          {{ $t("RailwayTariff.ChoosenCarriages") }}
        </div>
        <div class="text-center font-semibold mt-1">
          {{ carNumber }}, {{ itemType }} {{ carriageService?.type }}
        </div>
      </div>
      <div>
        <div class="text-center">{{ $t("RailwayTariff.AirConditioner") }}</div>
        <div style="color: #39b54a" class="text-center text-lg font-semibold">
          {{ carriageService?.service?.conditioner ? $t("Yes") : $t("No") }}
        </div>
      </div>
      <div>
        <div class="text-center">{{ $t("RailwayTariff.SelectedSeats") }}</div>
        <div
            v-if="selectedTickets?.length > 0"
            class="text-center text-lg font-semibold">
          {{ selectedTickets?.length }}
        </div>
        <div
            v-else
            class="text-center text-lg font-semibold">
          {{ selectedSeatsLength }}
        </div>
      </div>
      <div>
        <div class="text-center">{{ $t("RailwayTariff.TicketCost") }}</div>
        <div style="color: #1878f3" class="text-center text-lg font-semibold">
          {{ totalSum }} {{ $t("Currency") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watchEffect} from "vue";
import Cv from "@/components/RailwayBooking/Tariff/Cv";
import Kupe from "@/components/RailwayBooking/Tariff/kupe1";
import Plascartny from "@/components/RailwayBooking/Tariff/Plascartny";
import BizClass from "@/components/RailwayBooking/Tariff/BizClass";
import BizClass22 from "@/components/RailwayBooking/Tariff/BizClass22";
import BizClass26 from "@/components/RailwayBooking/Tariff/BizClass26";
import EcoClass36 from "@/components/RailwayBooking/Tariff/EcoClass36";
import EcoClass47 from "@/components/RailwayBooking/Tariff/EcoClass47";
import EcoClass49 from "@/components/RailwayBooking/Tariff/EcoClass49";
import EcoClass54 from "@/components/RailwayBooking/Tariff/EcoClass54";
import EcoClass39 from "@/components/RailwayBooking/Tariff/EcoClass39";
import VipClass11 from "@/components/RailwayBooking/Tariff/VipClass11";
import VipClass16 from "@/components/RailwayBooking/Tariff/VipClass16";
import TotalLarge from "@/components/RailwayBooking/Tariff/TotalLarge";
import TotalMini from "@/components/RailwayBooking/Tariff/TotalMini";
import {doOrderApi} from "@/services/railway_do_order";

export default {
  name: "RailwayBookingTariff",
  components: {
    Cv,
    Plascartny,
    Kupe,
    BizClass,
    TotalLarge,
    TotalMini,
    BizClass22,
    BizClass26,
    EcoClass36,
    EcoClass47,
    EcoClass49,
    EcoClass39,
    VipClass11,
    VipClass16,
    EcoClass54,
  },
  props: {
    trainRes: Array,
    showOnly: Boolean,
    selectedTickets: Array,
    carTariff: String,
  },
  emits: ["onChangeCarType"],
  setup(props, {emit}) {
    const trainResponse = ref(null);
    const {doOrderForm} = doOrderApi;
    const selectedSeatsLength = ref(null);
    const showKupe = ref(false);
    const showBilet = ref(false);
    let checked_cv = ref(false);
    let carNumber = ref(null);
    let freePlaces = ref(null);
    const itemType = ref("");
    const itempType = ref("");
    const tariff = ref("");
    const clickBilet = (item, parent) => {
      itempType.value = parent?.type;
      showBilet.value = true;
      carNumber.value = item?.number;
      freePlaces.value = item?.places?.split(",").length;
    };
    let index1 = ref(null);
    const kupe = (index, item) => {
      itemType.value = item?.type;
      tariff.value = item?.tariff;
      index1.value = index;
      showKupe.value = true;
      selectedSeatsLength.value = 0;
      clickBilet(item?.car[0], item);
    };
    const click_cv = () => {
      checked_cv.value = !checked_cv.value;
    };
    watchEffect(() => {
      if (
          props.trainRes &&
          props.trainRes.cars &&
          props.trainRes.cars.length > 0 &&
          !itemType.value
      ) {
        trainResponse.value = props.trainRes;
        if (props.carTariff) {
          trainResponse.value.cars = trainResponse.value.cars?.filter(
              (car) => car?.tariff === props.carTariff
          );
        }
        kupe(0, trainResponse.value.cars[0]);
        if (
            trainResponse.value.cars[0]?.car &&
            trainResponse.value.cars[0]?.car?.length > 0
        ) {
          clickBilet(
              trainResponse.value.cars[0]?.car[0],
              trainResponse.value.cars[0]
          );
        }
      }
    });

    watchEffect(() => {
      if (itempType.value || freePlaces.value) {
        const carriageData = {
          type: itempType.value,
          freePlaces: freePlaces.value,
        };
        emit("onChangeCarType", carriageData);
      }
    });

    const totalSum = computed(() => {
      if (trainResponse.value?.cars) {
        if (!props.showOnly)
          return (
              selectedSeatsLength.value *
              (parseInt(trainResponse.value.cars[index1.value]?.tariff) +
                  parseInt(trainResponse.value.cars[index1.value]?.comissionFee))
          );
        else {
          return (
              props.selectedTickets.length *
              (parseInt(trainResponse.value.cars[index1.value]?.tariff) +
                  parseInt(trainResponse.value.cars[index1.value]?.comissionFee))
          );
        }
      } else {
        return 0;
      }
    });

    const getTotalPrice = (tarif, comission) => {
      return parseInt(tarif) + parseInt(comission);
    };

    const carriageService = computed(() => {
      const carriageType = trainResponse.value?.cars?.find(
          (carType) => carType?.tariff === tariff.value
      )?.classService;
      if (carriageType) {
        const {service, type} = carriageType;
        return {service, type};
      } else {
        return {};
      }
    });

    return {
      // states
      itemType,
      index1,
      showKupe,
      showBilet,
      checked_cv,
      carNumber,
      doOrderForm,
      carriageService,
      selectedSeatsLength,
      totalSum,
      freePlaces,
      trainResponse,
      tariff,

      // methods
      getTotalPrice,
      click_cv,
      clickBilet,
      kupe,
    };
  },
};
</script>

<style scoped>
.bg-c {
  background-color: #1878f3;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.bg-k {
  background-color: #1878f3;
  color: white;
  display: block;
}

.bg-kk {
  display: none;
}

.bg-hover-tarif:hover {
  background-color: #1878f3;
  color: white;
  cursor: pointer;
}
</style>
