
import {defineComponent, PropType, ref} from "vue";
import Alert from "@/components/General/Alert.vue";
import RailwayFilterPanel from "@/components/Railway/FilterPanel.vue";
import {FlightSearchResult, Ticket} from "@/models/flight_models";
import {User} from "@/models/auth/user_models";
import {LocalDb} from "@/repository/localdb";
import {searchApi} from "@/services/railway_search";
import {trainTypeApi} from "@/services/railway_train_type";
import {filterData} from "@/composables/railwayFilter";
import {AvioneMessage} from "@/constants/error_messages";
import moment from "moment";
import {useI18n} from "vue-i18n";
import router from "@/router";
import Swal from "sweetalert2";
import CheckAvailability from "@/components/CheckAvailability.vue";

const currentUser = ref({} as User | null);
export default defineComponent({
  name: "RailwaySearchResult",
  props: {
    searchResult: {type: Object as PropType<FlightSearchResult>},
    isCharter: {type: Boolean, default: false},
  },
  setup() {
    const {Sedentary, Suite, Coupe, reservedSeat} = filterData;
    const {searchRes, SearchByDay, searchForm} = searchApi;
    const {onBuyClickk, showCheckAvailability, trainTypeForm} = trainTypeApi;
    const {locale} = useI18n({useScope: "global"});
    const selectedDate = searchForm.value.direction[0]?.depDate;
    return {
      SearchByDay,
      searchForm,
      searchRes,
      onBuyClickk,
      showCheckAvailability,
      Sedentary,
      Suite,
      Coupe,
      reservedSeat,
      trainTypeForm,
      locale,
      selectedDate,
    };
  },
  data() {
    return {
      alertShow: false,
      showMoreDetails: false,
      isFilterOpen: true,
      alertMessage: "",
      currentBuyId: "",
      isUserLoggedIn: false,
      showLoginForm: false,
      filteredTickets: {} as Ticket[],
      selectedTicket: {} as Ticket,
    };
  },
  components: {
    CheckAvailability,
    RailwayFilterPanel: RailwayFilterPanel,
    Alert: Alert,
  },
  computed: {
    range() {
      return Array.from({length: 8}, (_, i) => i - 3);
    },
    filterTrainResponse() {
      if (!this.Sedentary && !this.Suite && !this.Coupe && !this.reservedSeat) {
        return this.searchRes;
      } else {
        return this.searchRes.filter(
            (i: any) =>
                !!i.places.cars.find(
                    (d: any) =>
                        (d.indexType === "3" && this.reservedSeat) ||
                        (d.indexType === "4" && this.Coupe) ||
                        (d.indexType === "6" && this.Suite) ||
                        (d.indexType === "2" && this.Sedentary)
                )
        );
      }
    },
  },
  methods: {
    getTotalPrice(tarif: any, comission: any) {
      return parseInt(tarif) + parseInt(comission);
    },

    formatDate(i?: any) {
      const targetDate = this.selectedDate
          ? moment(this.selectedDate, "DD.MM.YYYY").add(i, "day")
          : moment().add(i, "day");

      if (targetDate.isBefore(moment(), "day")) {
        return;
      }

      const formattedDate = targetDate.format("DD-MMM");
      return formattedDate;
    },

    formatForSearchByDay(date: any) {
      const day = moment(date, "DD-MMMM").format("DD.MM.YYYY");
      this.SearchByDay(day);
    },

    formatDateLong(date: string) {
      if (date) {
        return moment(date, "DD.MM.YYYY")
            .locale(this.locale)
            .format("DD-MMMM-dddd");
      } else
        return moment()
            .locale(this.locale)
            .format("DD-MMMM-dddd");
    },

    isSameDate(i?: any) {
      const currentDate = moment(
          this.searchForm?.direction[0]?.depDate,
          "DD.MM.YYYY"
      ).format("DD-MM-YYYY");

      const iDate = moment(i, "DD-MMMM").format("DD-MM-YYYY");
      return currentDate == iDate;
    },

    openFalse(e: any) {
      this.isFilterOpen = e;
    },
    onBuyClick: async function (item: any) {
      this.logInUser();
      if (!this.isUserLoggedIn) {
        const result = await Swal.fire("", this.$t(AvioneMessage.CURRENT_USER_NOT_FOUND), "warning");
        if (result.isConfirmed) {
          router.push({
            path: this.$route.path,
            query: {showLoginForm: 'true'}
          });
        }
        return;
      }

      trainTypeApi.onBuyClickk(item);
      router.push({
        name: 'RailwayBooking',
        params: {lang: this.$route.params.lang},
      })
    },
    onFilterChanged(data: Ticket[]): void {
      this.filteredTickets = data;
    },
    priceFormat(price: number): string {
      return price.toLocaleString("uz-UZ", {
        style: "currency",
        currency: "UZS",
        minimumFractionDigits: 0,
      });
    },
    onOkClickEventHandler: function () {
      this.alertShow = false;
      this.alertMessage = "";
    },
    logInUser: function () {
      currentUser.value = LocalDb.getCurrentUser();
      this.isUserLoggedIn = currentUser.value != null;
      this.showLoginForm = false;
    }
  },
});
