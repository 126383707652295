
import {defineComponent, PropType} from "vue";
import {UserService} from "@/services/user_service";
import {OrderData} from "@/models/stored_data_models";
import {FlightOrderStatus} from '@/models/flight_order_status';
import {LocalDb} from "@/repository/localdb";
import {Passenger} from "@/models/order_modes";
import {Segment} from "@/models/flight_models";

export default defineComponent({
  name: "RailwayBookingPassengerList",
  props: {
    'passengers': {type: Object as PropType<Passenger[]>},
  },
  data() {
    return {
      showAlert: false,
      showEditForm: false,
      showAddForm: false,

    }
  }
});
