<template>
  <div>
    <svg width="17" height="18" viewBox="0 0 17 18" fill="yellow" xmlns="http://www.w3.org/2000/svg">
      <path :style="$route.path === '' ? 'fill:#0e75fc' : ''"
            d="M9.8421 6.2523L17 10.8V12.6L9.8421 10.3266V15.1506L12.5263 16.65V18L8.5 17.1L4.47368 18V16.65L7.15789 15.1497V10.3257L0 12.6V10.8L7.15789 6.2523V1.35C7.15789 0.991958 7.29929 0.64858 7.55099 0.395406C7.80268 0.142232 8.14405 0 8.5 0C8.85595 0 9.19732 0.142232 9.44901 0.395406C9.70071 0.64858 9.8421 0.991958 9.8421 1.35V6.2523Z"
            fill="#66676C"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "SamalyotSvg"
}
</script>

<style scoped>

</style>
