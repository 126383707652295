
import {defineComponent, PropType} from "vue";
import {FlightOrderStatus} from '@/models/flight_order_status';
import SearchResultItem from "@/components/Flight/SearchResultItem.vue";
import SearchResultMoreItem from "@/components/Flight/SearchResultMoreItem.vue";
import {Segment, Ticket} from "@/models/flight_models";
import {OrderData} from "@/models/stored_data_models";
import {LocalDb} from "@/repository/localdb";

export default defineComponent({
  name: "FlightPaymentDetail",
  components: {
    SearchResultItem,
    SearchResultMoreItem,
  },
  props: {
    'ticket': {type: Object as PropType<OrderData>}
  },
  data() {
    return {
      orderData: {} as OrderData | undefined,
      showMoreDetails: false,
      paidStatus: FlightOrderStatus.PAID
    }
  },
  created() {
    const pnrNumber = this.$route.params.id;
    if (pnrNumber) {
      const order: OrderData | undefined = LocalDb.getMyFlightOrder(pnrNumber.toString());
      if (order) {
        this.orderData = order;
      } else {
        window.location.href = '/';
      }
    }
  },
  methods: {
    priceFormat(price: number): string {
      return price.toLocaleString('uz-UZ', {
        style: 'currency',
        currency: 'UZS',
        minimumFractionDigits: 0
      })
    }
  },
});
