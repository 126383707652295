
import {defineComponent, PropType} from "vue";
import {searchApi} from "@/services/railway_search";
import {useI18n} from "vue-i18n";
import {filterData} from "@/composables/railwayFilter";

export default defineComponent({
  name: "RailwayFilterPanel",
  setup() {
    const {Sedentary, Suite, Coupe, reservedSeat} = filterData;
    const {searchRes} = searchApi;
    return {
      searchRes,
      Sedentary,
      Suite,
      Coupe,
      reservedSeat,
    };
  },
  data() {
    return {
      hasBaggage: false,
      noAirportChange: false,
      directFlight: false,

      isTopFilterOpen: true,
      isTransferOpen: true,
      isDurationOpen: true,
      isAirlineOpen: true,
      isPaymentTypeOpen: true,
      isAirportOpen: true,
      isTransplantDurationOpen: true,
    };
  },
});
