import {createApp} from 'vue'
import {createI18n} from 'vue-i18n'
import {createHead} from '@vueuse/head'
import App from './App.vue'
import router from './router'
import {store} from "@/vuex/store";
import messages from '@/i18n'
import ElementUI from 'element-plus';
import 'element-plus/dist/index.css';
import { MaskInput } from 'vue-3-mask';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore


import VueJivosite from '@bchteam/vue-jivosite'


const i18n = createI18n({
  legacy: false,
  globalInjection:true,
  locale: "ru",
  fallbackLocale: "uz",
  messages: messages
})

const head = createHead()
const app = createApp(App)
app.use(router);
app.use(i18n);
app.use(ElementUI)
app.use(store)
app.use(VueJivosite, { widgetId: 'nBR9RFzCDn' })
app.use(head)
app.component('MaskInput', MaskInput)
app.mount('#app')
