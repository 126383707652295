
import {defineComponent, PropType} from "vue";
import {Segment, Ticket} from "@/models/flight_models";
import flightBookingController from "@/composables/flight_booking";

export default defineComponent({
  name: "FlightBookingExtraInfo",
  props: {
    'ticket': {type: Object as PropType<Ticket>}
  },
  data() {
    return {
      segment: {} as Segment | undefined
    }
  },
  setup() {
    const {state, generatePassengers} = flightBookingController();
    return {
      ...state
    }
  },
  created() {
    if (this.ticket) {
      if (this.ticket.departure && this.ticket.departure.length > 0) {
        this.segment = this.ticket.departure[0];
      } else {
        this.segment = this.ticket.arrival;
      }
    }
  }

});
