<template>
  <div class="footer bg-body">
    <div class="container mx-auto pt-8 pb-4 text-white">
      <div class="flex flex-wrap -mx-2 sm:-mx-4 mb-4">
        <div class="w-6/12 md:w-3/12 px-2 sm:px-4 mb-4">
          <p class="mb-4 text-base sm:text-lg">
            <strong class="font-semibold">{{ $t('footer.company') }}</strong>
          </p>
          <ul class="space-y-3">
            <li>
              <router-link
                  :to="{name: 'About', params: {lang: $route.params.lang}}"
                  class="hover:underline">
                {{ $t('footer.oCompany') }}
              </router-link>
            </li>
            <li>
              <router-link
                  :to="{name: 'Home', params: {lang: $route.params.lang}}"
                  class="hover:underline">
                {{ $t('footer.Flights') }}
              </router-link>
            </li>
            <li>
              <router-link
                  :to="{name: 'PublicOffer', params: {lang: $route.params.lang}}"
                  class="hover:underline">
                {{ $t('footer.Offer') }}
              </router-link>
            </li>
            <li>
              <router-link
                  :to="{name: 'Privacy', params: {lang: $route.params.lang}}"
                  class="hover:underline">
                {{ $t('footer.Confidentiality') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="w-6/12 md:w-3/12 px-2 sm:px-4 mb-4">
          <p class="mb-4 text-base sm:text-lg">
            <strong class="font-semibold">{{ $t('directions.title') }}</strong>
          </p>
          <ul class="space-y-3">
            <li>
              <router-link
                  class="hover:underline"
                  :to="{name: 'FlightDirection', params: {fromCode: 'tas', toCode: 'mow', lang: $route.params.lang}}">
                {{ $t('directions.locations.tas.name') }} – {{ $t('directions.locations.mow.name') }}
              </router-link>
            </li>
            <li>
              <router-link
                  class="hover:underline"
                  :to="{name: 'FlightDirection', params: {fromCode: 'mow', toCode: 'tas', lang: $route.params.lang}}">
                {{ $t('directions.locations.mow.name') }} – {{ $t('directions.locations.tas.name') }}
              </router-link>
            </li>
            <li>
              <router-link
                  class="hover:underline"
                  :to="{name: 'FlightDirection', params: {fromCode: 'tas', toCode: 'kzn', lang: $route.params.lang}}">
                {{ $t('directions.locations.tas.name') }} – {{ $t('directions.locations.kzn.name') }}
              </router-link>
            </li>
            <li>
              <router-link
                  class="hover:underline"
                  :to="{name: 'FlightDirection', params: {fromCode: 'tas', toCode: 'ist', lang: $route.params.lang}}">
                {{ $t('directions.locations.tas.name') }} – {{ $t('directions.locations.ist.name') }}
              </router-link>
            </li>
            <li>
              <router-link
                  class="hover:underline"
                  :to="{name: 'FlightDirection', params: {fromCode: 'cit',toCode: 'ist', lang: $route.params.lang}}">
                {{ $t('directions.locations.cit.name') }} – {{ $t('directions.locations.ist.name') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="w-6/12 md:w-3/12 px-2 sm:px-4 mb-4">
          <p class="mb-4 text-base sm:text-lg"><strong class="font-semibold">{{ $t('footer.social') }}</strong></p>
          <ul class="space-y-3">
            <li>
              <a href="https://www.instagram.com/avione.uz/" class="flex items-center space-x-2 hover:underline">
                <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.465.066C7.638.012 8.012 0 11 0c2.988 0 3.362.013 4.534.066 1.172.053 1.972.24 2.672.511.733.277 1.398.71 1.948 1.27.56.549.992 1.213 1.268 1.947.272.7.458 1.5.512 2.67C21.988 7.639 22 8.013 22 11c0 2.988-.013 3.362-.066 4.535-.053 1.17-.24 1.97-.512 2.67a5.396 5.396 0 01-1.268 1.949c-.55.56-1.215.992-1.948 1.268-.7.272-1.5.458-2.67.512-1.174.054-1.548.066-4.536.066-2.988 0-3.362-.013-4.535-.066-1.17-.053-1.97-.24-2.67-.512a5.396 5.396 0 01-1.949-1.268 5.392 5.392 0 01-1.269-1.948c-.271-.7-.457-1.5-.511-2.67C.012 14.361 0 13.987 0 11c0-2.988.013-3.362.066-4.534.053-1.172.24-1.972.511-2.672a5.396 5.396 0 011.27-1.948A5.392 5.392 0 013.794.577c.7-.271 1.5-.457 2.67-.511h.001zm8.98 1.98c-1.16-.053-1.508-.064-4.445-.064-2.937 0-3.285.011-4.445.064-1.073.049-1.655.228-2.043.379-.513.2-.88.437-1.265.822a3.412 3.412 0 00-.822 1.265c-.151.388-.33.97-.379 2.043-.053 1.16-.064 1.508-.064 4.445 0 2.937.011 3.285.064 4.445.049 1.073.228 1.655.379 2.043.176.477.457.91.822 1.265.355.365.788.646 1.265.822.388.151.97.33 2.043.379 1.16.053 1.507.064 4.445.064 2.938 0 3.285-.011 4.445-.064 1.073-.049 1.655-.228 2.043-.379.513-.2.88-.437 1.265-.822.365-.355.646-.788.822-1.265.151-.388.33-.97.379-2.043.053-1.16.064-1.508.064-4.445 0-2.937-.011-3.285-.064-4.445-.049-1.073-.228-1.655-.379-2.043-.2-.513-.437-.88-.822-1.265a3.413 3.413 0 00-1.265-.822c-.388-.151-.97-.33-2.043-.379zm-5.85 12.345a3.67 3.67 0 102.641-6.846 3.67 3.67 0 00-2.641 6.846zM7.002 7.002a5.654 5.654 0 117.996 7.996 5.654 5.654 0 01-7.996-7.996zm10.906-.814a1.336 1.336 0 10-1.834-1.944 1.336 1.336 0 001.834 1.944z"
                      fill-rule="evenodd" clip-rule="evenodd" fill="#fff"/>
                </svg>
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a class="flex items-center space-x-2 hover:underline" href="https://t.me/Avione_uzbekistan"
                 target="_blank">
                <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M18.501 5.084l-15.158 6.22c-.492.17-.438.905.055 1.074l3.83 1.244 1.533 4.976c.11.396.602.51.875.226l2.244-2.148 4.268 3.223c.329.226.766.056.876-.34l2.955-13.118c.164-.961-.657-1.696-1.478-1.357zm-8.865 9.5l-.492 2.94-1.095-4.071L18.72 6.215l-9.084 8.369z"
                      fill="#fff"/>
                </svg>
                <span>Telegram</span>
              </a>
            </li>
            <li>
              <a class="flex items-center space-x-2 hover:underline" href="https://www.facebook.com/avioneuzbekistan">
                <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684v-2.86c-.82-.089-1.643-.131-2.467-.128-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.21h2.753v8.201h3.312z"
                      fill="#fff"/>
                </svg>
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a class="flex items-center space-x-2 hover:underline" href="https://youtube.com/@avioneuz">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                     viewBox="-35.20005 -41.33325 305.0671 247.9995">
                  <path
                      d="M93.333 117.559V47.775l61.334 34.893zm136.43-91.742c-2.699-10.162-10.651-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.555 7.652 7.603 15.655 4.904 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.904 56.849c2.699 10.163 10.65 18.165 20.747 20.883 18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.683-4.934c10.096-2.718 18.048-10.72 20.747-20.883 4.904-18.42 4.904-56.85 4.904-56.85s0-38.43-4.904-56.849"
                      fill="#fff"/>
                </svg>
                <span>YouTube</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="w-6/12 md:w-3/12 px-2 sm:px-4 mb-4">
          <p class="mb-4 text-base sm:text-lg"><strong class="font-semibold">{{ $t('footer.Download') }}</strong></p>
          <ul class="space-y-3">
            <li>
              <a class="inline-block hover:opacity-90 transition"
                 href="https://apps.apple.com/ru/app/avione/id1563864149">
                <img height="56" width="168" src="@/assets/img/app-store-smaller.png">
              </a>
            </li>
            <li>
              <a class="inline-block hover:opacity-90 transition"
                 href="https://play.google.com/store/apps/details?id=com.avione.uz">
                <img alt="app store" height="56" width="168" src="@/assets/img/google-play-smaller.png">
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr class="mb-8 border-white opacity-10">
      <div class="flex flex-col items-center lg:justify-between lg:flex-row">
        <p class="mb-4">
          ©&nbsp;{{ new Date().getFullYear() }}&nbsp;{{ $t('footer.sell') }}
        </p>
        <p class="mb-4">
          {{ $t('footer.legal') }}
        </p>
      </div>
    </div>
  </div>
</template>
