
import {defineComponent, PropType} from "vue";
import {FlightPaymentData, FlightPaymentResponse} from "@/models/payment_models";

export default defineComponent({
  name: "FlightBookingPaymentPriceChange",
  props: {
    'showMessage': Object as PropType<boolean>,
    'paymentInfo': Object as PropType<FlightPaymentData>,
  },
  methods: {
    onCancelClick: function () {
      this.$emit('onCloseClickEvent');
    },
    onOkClick: function () {
      this.$emit('onOkClickEvent');
    },
  }
})
;
