
import {defineComponent} from "vue";
import TheHeader from "@/components/TheHeader.vue";
import NavigatorLine from "@/components/NavigatorLine.vue";
import TheFooter from "@/components/TheFooter.vue";
import LeftMenu from "@/components/Profile/LeftMenu.vue";
import FlightOrderDetailHeader from "@/components/Profile/FlightOrderDetailHeader.vue";
import FlightOrderDetail from "@/components/Profile/FlightOrderDetail.vue";
import FlightOrderPassengers from "@/components/Profile/FlightOrderPassengers.vue";
import Loader from "@/components/General/Loader.vue";
import orderDetailsController from "@/composables/profile_flight_details";

export default defineComponent({
  name: "FlightOrderDetails",
  components: {
    TheHeader,
    NavigatorLine,
    LeftMenu,
    FlightOrderDetailHeader,
    FlightOrderDetail,
    FlightOrderPassengers,
    Loader,
    TheFooter,
  },
  setup() {
    const {state, loadFlightDetails} = orderDetailsController();
    return {...state, loadFlightDetails};
  },
  created() {
    const orderId = this.$route.params.id;
    this.loadFlightDetails(orderId.toString());
  },
});
