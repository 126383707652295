import {FlightClass, PassengerType} from "@/models/flight";

export default {
    flightClass: {
        [FlightClass.Economy]: 'Эконом',
        [FlightClass.Business]: 'Бизнес',
    },

    searchPanel: {
        yourMessageIsOutOf: 'Ваши данные устарели. Нажмите кнопку «Обновить».',
        cancel: 'Отмена',
        Update: 'Обновить',
        errors: {
            selectFromLocation: 'Выберите место отправления',
            selectToLocation: 'Выберите место прибытия',
            selectFromDate: 'Выберите дату отправления',
            selectToDate: 'Выберите дату возвращения',
        },
        there: "Туда",
        backAndForth: "Туда-обратно"
    },

    directions: {
        title: "Направления",
        popular: "Популярные направления",
        isPopular: {
            yes: 'Популярность данного направления остается неизменно высокой.',
            no: 'Данное направление имеет умеренную популярность.',
        },
        isTheSameCountry: {
            yes: 'соединяет два города в пределах одного государства.',
            no: 'соединяет два города в разных государствах.',
        },
        locations: {
            tas: {
                name: "Ташкент",
                genitiveCase: "Ташкента",
            },
            mow: {
                name: "Москва",
                genitiveCase: "Москвы",
            },
            ist: {
                name: "Стамбул",
                genitiveCase: "Стамбула",
            },
            cit: {
                name: "Шымкент",
                genitiveCase: "Шымкента",
            },
            kzn: {
                name: "Казань",
                genitiveCase: "Казани",
            },
            ugc: {
                name: "Ургенч",
                genitiveCase: "Ургенча",
            },
            bhk: {
                name: "Бухара",
                genitiveCase: "Бухары",
            },
            skd: {
                name: "Самарканд",
                genitiveCase: "Самарканда",
            },
            nma: {
                name: "Наманган",
                genitiveCase: "Намангана",
            },
            feg: {
                name: "Фергана",
                genitiveCase: "Ферганы",
            },
            oss: {
                name: "Ош",
                genitiveCase: "Оша",
            },
            tmj: {
                name: "Термез",
                genitiveCase: "Термеза",
            },
            led: {
                name: "Санкт-Петербург",
                genitiveCase: "Санкт-Петербурга",
            },
            ovb: {
                name: "Новосибирск",
                genitiveCase: "Новосибирска",
            },
            ikt: {
                name: "Иркутск",
                genitiveCase: "Иркутска",
            },
            svx: {
                name: "Екатеринбург",
                genitiveCase: "Екатеринбурга",
            },
            aer: {
                name: "Сочи",
                genitiveCase: "Сочи",
            },
            ala: {
                name: "Алматы",
                genitiveCase: "Алматы",
            },
            ufa: {
                name: "Уфа",
                genitiveCase: "Уфы",
            },
            kuf: {
                name: "Самара",
                genitiveCase: "Самары",
            },
            dme: {
                name: "Домодедово",
                genitiveCase: "Домодедова",
            },
            vko: {
                name: "Внуково",
                genitiveCase: "Внукова",
            },
            kej: {
                name: "Кемерово",
                genitiveCase: "Кемерова",
            },
            kja: {
                name: "Красноярск",
                genitiveCase: "Красноярска",
            },
            oms: {
                name: "Омск",
                genitiveCase: "Омска",
            },
            pee: {
                name: "Пермь",
                genitiveCase: "Перми",
            },
            mcx: {
                name: "Махачкала",
                genitiveCase: "Махачкалы",
            },
            ksq: {
                name: "Карши",
                genitiveCase: "Карши",
            },
            krr: {
                name: "Краснодар",
                genitiveCase: "Краснодара",
            },
        }
    },
    topRoutes: {
        title: 'Топ направлений из {fromGen}',
        titleWithoutLocation: 'Топ направлений',
        leadText: 'Города, в которые чаще всего летают',
    },

    countries: {
        russia: 'Россия',
        uzbekistan: 'Узбекистан',
        kazakhstan: 'Казахстан',
        turkey: 'Турция',
    },

    pages: {
        type: 'Туры',
        home: {
            title: 'Купить дешевые авиабилеты. Цены на прямые рейсы из Ташкента.',
            shortTitle: 'Купить авиабилеты в Ташкенте.',
            titleH1: 'Авиабилеты по доступным ценам. Прямые рейсы из Ташкента и других городов Узбекистана и России.',
            titleH2: 'Путешествуйте по Узбекистану по доступной цене!',
            description: 'Поиск дешевых авиабилетов на самолеты по авиакомпаниям и авиакассам по всему миру. Цена на прямые рейсы в Ташкент, Москву, Ургенч и других городов Узбекистана и России без пересадок.',
            leadText: `Покупка авиабилетов на нашем сайте – удобный способ планировать путешествие.
          Для приобретения билетов имеет смысл воспользоваться нашим интернет-сервисом.
          Так Вы сможете сэкономить и время, и деньги.`,
            content: `<h2 style="margin: 1rem 0;">Сколько стоит билет?</h2>
            <p style="margin: .65rem 0;">
              Стоимость авиабилетов не является постоянной величиной.
              Цифра формируется исходя из сезонности, спроса на данное направление в конкретную дату и пр.
              Расчет тарифов происходит с учетом следующих параметров:
            </p>
            <ul style="padding-left: 2rem;margin: 1rem;list-style: disc;">
              <li>День недели.</li>
              <li>Час вылета.</li>
              <li>Наличие стыковок.</li>
            </ul>
            <p style="margin: .65rem 0;">
              Цены на перелеты в выходные и праздничные дни традиционно выше.
              Перелеты в эти даты пользуются увеличенным спросом.
            </p>
            <p style="margin: .65rem 0;">
              В утреннее время цена авиабилетов более высокая.
              Это связано с увеличением спроса на перелеты со стороны путешествующих по работе.
              Транспортировка в одну сторону получается менее выгодной, чем одновременное приобретение
              перевозки туда и обратно. При этом ночные рейсы обычно обходятся дешевле.
            </p>
            <p style="margin: .65rem 0;">
              Многое зависит и от наличия пересадки. Билеты с длительной стыковой от 6-8 часов стоят дешевле.
              За путешествие без пересадки придется заплатить большую сумму.
              Прямые рейсы более удобны с позиции экономии времени. Это самый быстрый вариант добраться в пункт
              назначения. Всего за 4 часа Вы сможете преодолеть расстояние почти в 3 тысячи километров.
            </p>
            <p style="margin: .65rem 0;">
              Цена авиабилетов зависит от заполняемости воздушного судна, проводимых акций, скидок.
              Чем меньше свободных мест остается к дате вылета, тем дороже обойдется путешествие.
            </p>`,
        },
        directions: {
            title: 'Дешёвые авиабилеты {from} — {to}. Цены на прямые рейсы из {fromGen}.',
            titleH1: 'Авиабилеты {from} — {to} по доступным ценам. Прямые рейсы из {fromGen}.',
            description: 'Авиабилеты {from} — {to} по низким ценам. Сравнение цен и покупка билетов разных авиакомпаний в одну или обе стороны. Поиск прямых рейсов и вариантов с пересадками из {fromGen} в {to}.',
            content: `<h2 style="margin-bottom: 1rem;">
                  Бронирование и покупка авиабилетов из {fromGen} в {to}
                </h2>
                <p style="margin: .65rem 0;">
                  В 2022 году привычные правила формирования тарифов могли претерпеть некоторые изменения.
                  На то, сколько стоит перелет, влияет и курс валют, и другие факторы.
                  { popularText }
                  Поэтому имеет смысл заблаговременно позаботиться о покупке билетов на воздушное судно,
                  следующее указанным маршрутом.
                </p>
                <h3 style="margin: 1rem 0;">
                  Популярность авиабилетов {fromGen} — {to}
                </h3>
                <p style="margin: .65rem 0;">
                  Маршрут полета из {fromGen} в {to} соединяет два города.
                  Воздушное сообщение по данному направлению развито отлично.
                  Ежедневно между аэропортами курсируют самолеты нескольких перевозчиков.
                  Имеет смысл купить авиабилеты заранее, чтобы сэкономить деньги.
                  Наиболее низкая цена обычно при бронировании за 2 месяца до отправления.
                  Ближе к дате вылета стоимость билетов повышается.
                  С учетом конкретного месяца может происходить изменение цены перелета по
                  данному маршруту – как рост, так и снижение.
                  Обычно самые выгодные предложения разбирают в первую очередь.
                  Однако иногда можно купить билет по низкой цене и накануне отправления.
                </p>
                <p style="margin: .65rem 0;">
                  На странице представлены все доступные предложения для перелетов.
                  Все указанные по результатам поиска рейсы регулярные.
                  Авиабилеты можно отфильтровать по заданным параметрам.
                  Удобная форма поиска с моментальным подтверждением бронирования выполняет подбор
                  проездных документов с гарантированным наличием мест.
                  Нужно лишь обозначить требуемую дату вылета, число пассажиров, класс перелета.
                </p>`
        },
        charterFlights: {
            title: 'Чартерные рейсы',
            notFound: 'К сожалению, на данный момент нет доступных чартерных рейсов.',
        }
    },

    title: {
        titleLang: "Avione.uz - Авиабилеты по доступным ценам"
    },

    Мужчина: "Мужчина",
    Женщина: "Женщина",

    search: {
        Search: "Поиск...",
        fromLocation: "Откуда",
        toLocation: "Куда",
        button: "Найти",
        Back: "Обратно",
        There: "Туда",
        passengers: "пассажиры",
        Passengers: "Пассажиры",
        ChoosePlaceOfArrival: "Выберите место прибытия",
        SelectYourDeparturePoint: "Выберите место отправления",
        allAirports: "все аэропорты"
    },
    search_result: {
        options: "вариантов",
        Filters: "Фильтры",
        TheCheapest: "Самый дешевый",
        Buy: "Купить",
        FlightDetails: "Детали перелёта",
        CollapseDetails: "В пути:",
        OnMyWay: "В пути:",
        Transplants: "Пересадки:",
        PieceOfHandLuggage: "1 место ручной клади",
        TransferTo: "Пересадка в",
        OnTheWay: 'В пути',
        ChooseTrain: 'выбрать поезд',
        NotLeftSeat: 'Мест не осталось'
    },
    booking_result_popup: {
        BookingResult: "Результат бронирования",
        YourBooking: "Ваша бронь №",
        createdSuccessfully: " создана успешно.",
        ValidityOfYourBooking: "Срок действия вашего бронирования до - {date}",
        Later: "Позже",
        Pay: "Оплатить"
    },
    contact_info: {
        RecipientContacts: "Контакты получателя",
        Phone: "Телефон",
        Email: "Электронная почта",
        WeWillSendTicketsInformAbout: "Отправим билеты, сообщим об изменениях в поездке. Свяжемся, если нужно будет уточнить информацию."
    },
    extra_info: {
        Data: "Данные",
        HandLuggage: "Ручная кладь: 1 сумка",
        Luggage: "Багаж:",
        Adult: "1 взрослый"
    },
    home: {
        flights: "Авиабилеты",
        Hotels: "Отели",
        Welcome: "Добро пожаловать",
        tell: "Расскажем, подскажем покажем, где можно отдохнуть",
        show: "Расскажем, подскажем и покажем, где можно отдохнуть",
        bonuses: "Пользуясь мобильным приложением, вы получаете почти в 2 раза больше бонусов, чем на сайте.",
        main: "Главная",
        searching: "Результаты поиска",
        Checkout: "Оформление заказа",
        variant: "вариантов",
        Top: "Топ фильтр",
    },
    About: {
        AboutUs: "О нас",
        newMobileApplication: "Avione - новое мобильное приложение в Узбекистане.Здесь Вы можете найти авиабилеты,самостоятельно сравнить цены и купить всего за несколько секунд. Поиск,сравнение и покупка авиабилетов с онлайн поддержкой 24 на 7",
        BuyAirTickets: "Купить авиабилеты всего за несколько минут в любую точку мира, билеты на самолет отавиакомпаний Аэрофлот, Победа, S7 (с7, Сибирь), Ямал, Уральские авиалинии, Utair (Ютейр), Air Baltic, лоускостера Air Berlin, Lufthansa, Air France, Emirates, British Airways и многих других в одном приложении.",
        searchForAirTickets: "Поиск авиабилетов осуществляется в любое время суток в режиме онлайн. Больше нет необходимости ехать в офис кассы за билетом. Авиабилет придет прямо в ваше приложение Avione и на вашу электронную почту, его нужно просто распечатать и взять с собой в аэропорт.",
        ItISBetterTSearch: "Искать и бронировать авиабилеты лучше за 4-6 месяцев до желаемой даты вылета. Для этого воспользуйтесь\n" +
            "        приложение Avione, на котором представлены предложения от более 700 авиакомпаний. Здесь вы легко сможете\n" +
            "        мониторить динамику стоимости, время и день вылета и в результате заказать билет на самолет через интернет.",
        yearsOld: "12 лет",
        MarketExperience: "Опыта на рынке",
        Узбекистана: "Узбекистана",
        SatisfiedCustomers: "Довольных клиентов",
        AirlinesFor: "Авиакомпаний для",
        ticketSearch: "поиска билетов",
        CountriesSearch: "Стран для поиска",
        hotels: "отелей",
        HowBuyTicketThroughAvione: "Как купить билет через Avione:",
        OpenAvioneApp: "Откройте приложение Avione",
        SpecifyTheTimeCityOf: "Укажите время, города вылета и прилета",
        YouCanUseFiltersChooseTicket: "Вы можете воспользоваться фильтрами и выбрать авиабилет, подходящее по большому количеству разных",
        ChooseThePlaneTickets: "Выберите билеты на самолет, которые вам подходят",
        CreateAccountTheAvioneApp: "Создайте учетную запись в приложении Avione",
        EnterPassengerDetails: "Укажите данные пассажиров и оплатите авиабилеты онлайн",
        TheFlightTicketWillCome: "Авиабилет придет прямо в ваше приложение Avione и на вашу электронную почту.",
        AirfarePricesIncludingFees: "Цены на авиабилеты с учетом всех сборов",
        AllFlightPricesFinal: "Все цены на авиарейсы окончательные. Никаких доплат после покупки",
        ToReturnTheTicket: "Для возврата билета вы можете обратиться в нашу службу поддержки, они помогут быстро вернуть платеж",
        Support: "Служба поддержки",
        OurSuportCovers: "Наша поддержка охватывает весь спектр услуг, направленных на оказание помощи пользователям 24/7, которые смогут получить полезный ответ на запрос. Напишите или позвоните нам в любое время, мы будем рады Вам помочь!",
        OurContacts: "Наши контакты",
        Thanks: "Спасибо,",
        WeHappyHelpYou: "Мы рады Вам помочь. Команда Avione.",
        AdditionalServices: "Дополнительные услуги",
        WithUsTheTripMoreComfortable: "С нами поездка будет комфортнее и безопаснее, потому что у нас можно оформить страхование перелёта, заранее выбрать место в салоне",
        FlightInsurance: "Страхование перелета",
        FlightDelayAndLostLuggage: "Отмена, задержка рейса и утрата багажа",
        Notifications: "Уведомления",
        CancellationaSiaSMSAndEmail: "Изменения или отмена рейса по SMS и e-mail",
        TicketReturn: "Возврат билета",
        ReasonForReturnCanBeAny: "Причина возврата может быть любой",
        OnlineCheckInForFlight: "Онлайн-регистрация на рейс",
        CheckYouInForFlight: "Зарегистрируем вас на рейс",
        BuyMoreLuggage: "Докупить багаж",
        ForOneOrAllPassengers: "Для одного или всех пассажиров",
        AllInclusive: "Всё включено",
        IncludesFlightInsuranceService: "Включает страхование перелёта, уведомления, отменяет сервисный сбор OneTwoTrip."
    },
    footer: {
        company: "Компания",
        oCompany: "О компании",
        Flights: "Авиабилеты",
        Information: "Информация",
        Offer: "Оферта",
        Confidentiality: "Confidentiality",
        social: "Мы в соцсетях",
        sell: "Онлайн продажа авиабилетов в Ташкенте",
        Download: "Скачать информацию",
        legal: "Город Ташкент, Чилонзор, Катартал-8. Частное предприятие «Avione», зарегистрированному в качестве юридического лица по законодательству Республики Узбекистан 05.03.2020 г., Свидетельство о регистрации №824437"
    },

    filter_panel: {
        Top: "Топ фильтр",
        luggage: "С багажом",
        noChange: "Без смены аэропорта",
        Direct: "Прямые",
        CheapFirst: "Сначала дешевые",
        DearOnesFirst: "Сначала дорогие",
        ByDuration: "По продолжительности",
        EarlyDeparture: "Ранний отъезд",
        LateCheckOut: "Поздний отъезд",
        Airlines: "Авиакомпании",
        FilterCleaning: "Очистка фильтра",
        Filter: "Фильтр"
    },
    login: {
        account: "Вход в личный кабинет",
        phoneNumber: "Телефонный номер",
        Agree: "Я ознакомился и согласен с условиями",
        publicOffer: "Публичной оферты",
        privacyPolicy: "Политикой конфиденциальности",
        comeIn: "Войти",
        EnterCode: "Введите код, который мы отправили на Ваш номер ",
        Code: "Отправить код еще раз",
        EnterActivationCode: "Введите код активации",
        Proceed: "Продолжить",
        Register: "Зарегистрироваться",
        Profile: "Профиль",
        Exit: "Выход"
    },
    passenger: {
        Surname: "Фамилия",
        Name: "Имя",
        MiddleName: "Отчество",
        DateOfBirth: "Дата рождения",
        Floor: "Пол",
        request: "Мы ищем лучшие варианты по вашему запросу",
        PassengerData: "Данные пассажиров",
        Ticket: "Билет",
        Adult: "взрослый",
        Children: "детский",
        ChildrenDiscount: "Имеется скидка для детей от 5 до 10 лет",
        Child: "для ребенка до 5 лет (безместный)",
        AddChild: "Добавить ребенка до 5 лет",
        RemoveChild: "Удалить место для ребенка",
        EnterYourPersonalDetail: "Введите личные данные, как указано в документе, по которому вы полетите. Мы автоматически переведем текст на латиницу — не пугайтесь, все нормально",
        PassengerType: "Тип пассажира",
        EnterPassengersLastName: "Введите фамилию пассажира",
        Email: "Электронная почта",
        DocumentType: "Тип документа",
        DocumentNumber: "Номер документа",
        Region: "Регион",
        PassportIssueDate: "Дата выдачи паспорта",
        PassportExpirationDate: "Срок действия паспорта",
        Citizenship: "Гражданство",
        FullName: "Полное имя",
        Validity: "Срок действия",
        NewDocument: "Новый документа",
        Phone: "Телефон",
        man: "Муж.",
        woman: "Жен.",
        Another: "Другой",
        Cancel: "Отмена",
        Save: "Сохранить",
        total: "Общий",
        singular: 'Пассажир',
        plural: 'Пассажиры',
        selectPassengerName: "Выберите имя пассажира",
        type: {
            [PassengerType.Adult]: 'Взрослый',
            [PassengerType.Kid]: 'Дети',
            [PassengerType.Baby]: 'Младенец',
        },
        age: {
            [PassengerType.Adult]: '12 и старше',
            [PassengerType.Kid]: '2-11',
            [PassengerType.Baby]: '0-1',
        }
    },
    AvioneMessage: {
        PAY_LATER: "Позже",
        CONTINUE: "Продолжать",
        PAYMENT_BOOKING_VOID_FAIL: "К сожалению, нам не удалось аннулировать ваш билет. Повторите попытку позже.",
        TICKET_VOIDING: "Аннулирование забронированного билета ...",
        PAYMENT_BOOKING_VOID_SUCCESS: "Ваш билет успешно аннулирован.",
        TICKET_PRICE_CHANGED: "Цена билета изменилась, вы хотите продолжить и платить по новой цене?",
        TICKET_PRICE_CHANGED_TITLE: "Цена билета изменилась",
        PHONE_NUMBER_REQUIRED: 'Номер телефона обязателен',
        SELECT_DEPARTURE_DATE: 'Пожалуйста, выберите дату полета',
        SELECT_FLIGHT_LOCATION: "Пожалуйста, выберите место полета",
        PAYMENT_STATUS_SUCCESS: "Билеты выписаны успешно!",
        PAYMENT_STATUS_CHECKING_DISCHARGE: "Подождите, производится выписка билета...",
        discharged: "Выписана",
        eneterMiddleNamePassenger: "Введите отчество пассажира",
        bookingTicketNotFound: "Билет для бронирования не найден",
        sureToCancelBooking: "Вы уверены, что отменяете бронирование?",
        WrongEmail: "Введите Электронная почта",
        WrongPassportIssue: "Введите дата выдачи паспорта",
        WrongPassportExpire: "Введите срок действия паспорта",
        unable: "Нам не удалось определить формат вашего номера телефона",
        selectRate: "Пожалуйста, выберите тариф",
        loginAgain: "Пожалуйста, для продолжения сначала авторизуйтесь",
        EnterPassengerName: "Введите имя пассажира",
        EnterPassengersLastName: "Введите фамилию пассажира",
        EnterPassengersDateOfBirth: "Введите дату рождения пассажира",
        ChooseGender: "Выберите пол",
        SelectNationality: "Выберите гражданство",
        EnterPassengersPhoneNumber: "Введите номер телефона пассажира",
        SelectDocumentType: "Выберите тип документа",
        EnterDocumentNumber: "Введите номер документа",
        WrongPhoneNumber: "Неправильный номер телефона",
        PleaseSelectPaymentMethod: "Пожалуйста, выберите способ оплаты",
        WeWillRedirectYouPaymoPageForPayment: "Мы перенаправим вас на страницу Payme для оплаты",
        WeWillRedirectYouAvipayPageForPayment: "Мы перенаправим вас на страницу Avipay для оплаты",
        CheckingBillPayments: "Проверка оплаты счетов...",
        verifyYourPayments: "Мы не смогли подтвердить ваши платежи. Вы можете оплатить до истечения срока забронированного билета",
        PleaseWait: "Пожалуйста, подождите...",
        OrderNotFound: "Заказ не найден",
        WrongCode: "Неправильный код",
        PleaseEnterSMSCode: "Пожалуйста, введите sms-код, который мы вам отправили",
        AllFieldsRequired: "Все поля обязательны для заполнения!",
        WeWereUnable: "Нам не удалось удалить ваш заказ, повторите попытку позже.",
        PleaseLogIn: "Пожалуйста, авторизуйтесь, чтобы продолжить бронирование",
        AreYouSureYouCancelBourBooking: "Вы действительно хотите аннулировать бронирование?",
        // YourTicketHasBeenSuccessfullyCancelled:"Ваш билет успешно аннулирован.",
        DownloadTicket: "Скачать билет",
        NotEnoughInBalance: 'Не хватает баланса',

        PassportExpire: 'До истечения срока действия вашего паспорта осталось менее 3 месяцев, сначала обновите свой паспорт'
    },
    PersonalArea: {
        PersonalArea: "Личный кабинет",
        Orders: "Заказы",
        Balans: "Баланс",
        MyOrders: "Мои заказы",
        MyVoid: 'Отменено',
        MyBookings: "Мои бронирования",
        GoOut: "Выйти",
        Notebook: "Записная книжка"
    },
    CheckAviabilty: {
        AvailabilityCheck: "Проверка наличия мест",
        TariffAvailabilityCheck: "Проверка наличия тарифов",
        PleaseWaitBookingInProgress: "Подождите, идет процессс бронирования..."
    },
    flight_detail: {
        TheCheapest: "Самый дешевы",
        FlightDetails: "Детали перелёта",
        CollapseDetails: "Свернуть детали",
        WaitingForDischarge: "Ожидает выписки",
        Canceled: "Отменена",
        Discharged: "Выписана",
        AreYouSureYouantDelete: "Вы действительно хотите удалить?",
        TimeLeftPay: "Осталось времени на оплату",
        PNRNumber: "Номер PNR",
        OrderNumber: "Номер заказа",
        AmountPayable: "Сумма к оплате",
        TermsOfReturnExchange: "Условия возврата и обмена",
        Cancellation: "Отмена брони",
        PayForTOrder: "Оплатить заказ",
        Order: 'Заказ'
    },
    payment_type: {
        SelectPaymentMethod: "Выберите способ оплаты",
        TicketsIssuedSuccessfully: "Билеты выписаны успешно!",
        ThankYouForUsingAvione: "Thank you for using Avione.",
        ToTheMain: "На главную",
        ThroughPaymentSystem: 'Через платежную систему',
        RemoveFromBalance: 'Снять с баланса',
        UseMixedPayment: 'Недостаточно средств, пожалуйста, воспользуйтесь “смешанной оплатой”',
        MixedPayment: 'Смешанная оплата',
        HowMuchFromBalance: "Какую сумму хотите оплатить с баланса?",
        Left: 'Остальные',
        WillBePaidThroughPaymentSystem: "сум будет оплачена через платежную систему"
    },
    price_change_popup: {
        TicketPriceHasChanged: "Цена билета изменилась",
        OldPrice: "Старая цена",
        NewPrice: "- Новая цена",
        TheTicketPriceHasChanged: "Цена билета изменилась, вы хотите продолжить и платить по новой цене?",
        Later: "Позже",
        Continue: "Продолжать"
    },
    balance_replanish_popup: {
        ReplanishBalance: "Пополнение баланса",
        EnterReplenishmentAmount: "Введите сумму пополнение",
        Replanish: "Пополнить"
    },
    steps: {
        Booking: "1. Бронирование",
        Payment: "2. Оплата",
        GettingTicket: "3. Получение билета",
        TicketBooking: "Бронирование билета",
        Payments: "Оплата",
        PaymentStatus: "Статус платежа",
        AirlineFares: "Тарифы авиакомпании",
        TashkentDubai: "Ташкент - Дубай"
    },
    Basic: {
        Cancel: "Отмена",
        ToBook: "Бронировать",
        GoToThePayment: "Перейти к оплате",
        DownloadTicket: 'Скачать билет'
    },
    empty_result: {
        Filtr: "Фильтр",
        CheapFirst: "Сначала дешевые",
        DearOnesFirst: "Сначала дорогие",
        MostWanted: "Самые востребованные",
        TopFilter: "Топ фильтр",
        WithLuggage: "С багажом",
        NoAirportChange: "Без смены аэропорта",
        Direct: "Прямые",
        FlightDetails: "Детали перелёта",
        Buy: "Купить"
    },
    avione_ads: {
        BigCashbackWallet: "Кошелек с&nbsp;большим кэшбеком 2% кэшбэк при каждой покупке.",
        ToLearnMore: "Узнать больше",
        FindOutAboutUnusualTravel: "Узнайте о необычных направлениях для путешествий в&nbsp;нашем&nbsp;Telegram.",
        WeHaveCreated: "Мы создали 24/7&nbsp; колл-центр, куда вы можете отправлять вопросы &nbsp;и&nbsp; запросы по телефону."
    },
    location_history: {
        SearchHistory: "История поисков",
        ShowMore: "Показать больше",
        regionNijniy: "Нижний Новгород (GOJ) – Шереметьево (SVO)",
        EconomyClass: "26 апр, 1 взрослый, Эконом класс",
        regionTashkent: "Ташкент (TAS) – Шереметьево (SVO)"
    },
    privacy: {
        AvionePrivacyPolicy: "Политика конфиденциальности Avione",
        AboutUs: "О нас",
        privatelyOwnedCompany: "Частное предприятие Avione, очень серьезно относятся к Вашей конфиденциальности. Настоящая Политика\n" +
            "          конфиденциальности разработана с учетом Ваших потребностей, и важно, чтобы Вы имели полное представление о\n" +
            "          нашей практике сбора и использования личной информации, позволяя Вам при этом полностью контролировать личную\n" +
            "          информацию, которую Вы предоставляете Avione.",
        OnTermsOfThisAgreement: "На условиях настоящего Соглашения об обработке персональных данных (далее - Соглашение), Частное предприятие\n" +
            "          «Avione», зарегистрированному в качестве юридического лица по законодательству Республики Узбекистан\n" +
            "          05.03.2020 г., Свидетельство о регистрации №824437, почтовый адрес которого находится: Республика Узбекистан,\n" +
            "          г.Тошкент, Чилонзор, Катартал-8, Катортол (далее – «Агент») предоставляет пользователям приложения Avione,\n" +
            "          расположенного по адресу https://play.google.com/store/apps/details?id=com.avione.uz, право пользования\n" +
            "          приложения, при помощи которых Пользователи могут осуществить поиск авиабилетов и прочих услуг и предложений\n" +
            "          на рынке авиабилетов.",
        AboutThisPrivacyPolicy: "О настоящей Политике конфиденциальности",
        ThePrivacyPolicyAppliesAllAvioneDevices: "Политика конфиденциальности применяется ко всем устройствам, веб-сайтам и приложениям Avione, которые\n" +
            "          упоминаются или ссылаются на настоящую Политику конфиденциальности. Настоящая Политика конфиденциальности\n" +
            "          объясняет, как Avione собирает, использует, раскрывает, обрабатывает и защищает любую личную информацию,\n" +
            "          которую Вы нам предоставляете во время использования наших продуктов и услуг, доступ к которым Вы можете\n" +
            "          получить через веб-сайт https://www.avione.uz и наши приложения, которые мы предоставляем на наших мобильных\n" +
            "          устройствах. Мы будем использовать Вашу личную информацию строго в соответствии с настоящей Политикой\n" +
            "          конфиденциальности. Если этого требует контекст, личная информация также должна включать в себя\n" +
            "          конфиденциальные личные данные или информацию, которая может быть отнесена к категории в соответствии с\n" +
            "          применимым правом.",
        TheAvioneApplicationSellsAndBook: "Важное: приложения Avione осуществляет непосредственную продажу и бронирование авиабилетов пользователям.\n" +
            "          Администратор не выступает в качестве Поставщика услуг. Администратор со своей стороны обязуется делать все\n" +
            "          возможное для решения проблем пользователей, возникших при использовании приложения Avione, в том числе\n" +
            "          оказать пользователям помощь во взаимодействии с Поставщиком услуг.",
        thisDocumentWithAllAdditions: "Соглашение – настоящий документ со всеми дополнениями, изменениями и указанными в нем обязательными\n" +
            "          документами, а также договор, заключенный между Avione и Пользователем на его основании",
        onlineServiceAndroidAddress: "Приложения – онлайн-сервис по адресу Android: https://play.google.com/store/apps/details?id=com.avione.uz для\n" +
            "          поиска информации об авиабилетах, бронировании и приобретении авиабилетов, а также для облегчения\n" +
            "          (автоматизации) процесса их приобретения.",
        capableNaturalPerson: "Пользователь – дееспособное физическое лицо, достигшее 18 лет, имеющее законное право вступать в договорные\n" +
            "          отношения с Поставщиком услуг, в соответствии с 3 Главой Гражданского Кодекса Республики Узбекистан, способный\n" +
            "          в том числе совершать Заказ или бронировать услуги на приложения Avione, исключительно для личных нужд, не\n" +
            "          связанных с осуществлением предпринимательской деятельности.",
        PersonalDataAnyInformationThat: "Персональные данные – любая информация, которую Пользователь предоставляет о себе самостоятельно при\n" +
            "          регистрации на приложения Avione, а также в процессе дальнейшего использования приложения Avione. К такой\n" +
            "          информации, в частности, следует относить: Ваше Имя, Фамилия, номер мобильного телефона, адрес электронной\n" +
            "          почты, Дата Рождения, Номер документа, Срок действия паспорта, Дата выдачи паспорта, Гражданство, Пол, а также\n" +
            "          другую информацию, включая ряд технических данных: IP адрес, историю посещений приложения и другие.",
        PersonalAccountPersonalSection: "Личный кабинет — персональный раздел приложения, к которому Пользователь получает доступ после прохождения\n" +
            "          регистрации и/или авторизации на приложения. Личный кабинет предназначен для хранения персональных данных\n" +
            "          Пользователя (личные данные и документы), оформление Заказов, просмотр информации о совершенных Заказах,\n" +
            "          стадии их выполнения, текущее состояние выставленных счетов, и получения уведомлений.",
        TheUserWhenClickingOn: "Пользователь при нажатии на текст Соглашение об использовании персональных данных на форме авторизации, может\n" +
            "          ознакомится с информацией об условиях сбора и хранения Персональных данных.",
        WhenRegisteringTheUser: "При регистрации Пользователь самостоятельно, свободно, своей волей и в своих интересах дает Администратору\n" +
            "          бессрочное письменное согласие на любые способы обработки своих персональных данных.",
        TheUserMustIndependentlyFamiliarize: " Пользователь самостоятельно должен ознакомиться с условиями использования его персональных данных до начала их\n" +
            "          обработки. Приложения прекращает обработку Персональных данных и уничтожает их сразу после удаления учетной\n" +
            "          записи Пользователя. В этом случае Администратор сохраняет только обезличенные персональные данные\n" +
            "          Пользователя в своих системах баз данных.",
        TheAdministratorIsNotResponsible: "Администратор не отвечает за полноту и актуальность сведений о Пользователях. Пользователь вправе в любой\n" +
            "          момент самостоятельно отредактировать в своем Аккаунте предоставленные им при регистрации или авторизации\n" +
            "          Персональные данные.",
        HowCanWeHelpYou: "How can we help you",
        UltimatelyWeStriveTo: "В конечном итоге, мы стремимся предоставить наилучшие условия для всех наших пользователей. Если у Вас\n" +
            "          возникнут вопросы о наших методах обработки данных, описанных в настоящей Политике конфиденциальности, Вы\n" +
            "          можете перейти по адресу https://www.avione.uz/support для решения конкретных проблем. Мы будем рады помочь\n" +
            "          Вам.",
        WhatKindOfInformation: "1. Какого рода информацию мы собираем и как мы ее используем",
        WhatKindOfInformationcollect: "1.1. Какого рода информацию мы собираем",
        InOrderProvideYouWithOur: "Для предоставления Вам наших услуг мы попросим Вас предоставить личную информацию, необходимую для\n" +
            "          предоставления Вам этих услуг. Мы будем осуществлять сбор только той информации, которая необходима для\n" +
            "          указанных, конкретных, явных и законных целей, и будем следить за тем, чтобы такая информация не\n" +
            "          обрабатывалась в дальнейшем способом, несовместимым с этими целями. У Вас есть право решать, предоставлять ли\n" +
            "          запрошенную нами информацию или нет, но в большинстве случаев, если Вы не предоставите свою личную информацию,\n" +
            "          мы не сможем предоставить Вам наши продукты или услуги или ответить на Ваши запросы.\n" +
            "          Приложения со своей стороны синхронизирует историю поиска, настройки и избранное в личном аккаунте\n" +
            "          Пользователя.",
        InformationYouProvideToUs: "1.1.1. Информация, которую Вы нам предоставляете\n" +
            "          Чтобы использовать наше приложение, вы должны пройти нашу регистрацию.\n" +
            "          Чтобы зарегистрироваться в нашей системе, Вам необходимо предоставить Ваше Имя, Фамилия, номер мобильного\n" +
            "          телефона, адрес электронной почты, Дата Рождения, Номер документа, Срок действия паспорта, Дата выдачи\n" +
            "          паспорта, Гражданство, Пол. Чтобы забронировать билет в нашей системе Вам необходимо предоставить информация о\n" +
            "          вашем пассажире Имя, Фамилия, номер мобильного телефона, адрес электронной почты, Дата Рождения, Номер\n" +
            "          документа, Срок действия паспорта, Дата выдачи паспорта, Гражданство, Пол.",
        InformationCollectedWhenYou: "1.1.2. Информация, собираемая во время использования Вами нашими службами\n" +
            "          Администратор может использовать трансграничную передачу персональных данных на территорию иностранного\n" +
            "          государства иностранному юридическому лицу в целях исполнения договора с Пользователем. При достижении всех\n" +
            "          поставленных целей Администратор может гарантировать Пользователю уничтожение его Персональных данных на\n" +
            "          территории иностранного государства.",
        NonIdentifyingInformation: "1.1.3. Неидентифицирующая информация\n" +
            "          Мы также можем осуществлять сбор других типов информации, которая не связана прямо или косвенно с человеком и\n" +
            "          которая может быть определена как личная информация в соответствии с применимым местным законодательством.\n" +
            "          Такая информация называется неидентифицирующей. Мы можем собирать, использовать, передавать и раскрывать\n" +
            "          неидентифицирующую информацию. Ниже приведены несколько примеров информации, которую мы собираем, а также\n" +
            "          способы ее использования в агрегированном неидентифицирующем формате:",
        WeMayIncludeStatistical: "Мы можем включать статистические данные, которые генерируются, когда Вы используете Avione (например,\n" +
            "              неидентифицирующую информацию об устройстве, события ежедневного использования, события доступа к\n" +
            "              страницам).",
        NetworkNonitoringData: "Данные мониторинга сети (например, время запроса, количество запросов и запросов об ошибках и т. д.).",
        ApplicationFailureEvents: "События сбоев работы приложений (например, автоматическое создание журналов после обнаружения сбоев в\n" +
            "              работе приложений и т. д.).",
        ThePurposeOfThisCollectionOf: "Целью такого сбора информации является улучшение качества предоставляемых Вам услуг. Тип и объем\n" +
            "              собираемой информации зависит от того, как Вы используете наши продукты и/или услуги.",
        WeSummarizeSuchInformationIn: "Мы суммируем такую информацию, чтобы предоставлять Вам наиболее полезную информацию и понять, какие части\n" +
            "              наших приложений, веб-сайтов Вам наиболее интересны. Например, нам может потребоваться только количество\n" +
            "              активных пользователей за день. Но нам не нужно знать, кто конкретно был активен в конкретный день.\n" +
            "              Поэтому для статистического анализа достаточно агрегированных данных. Мы постараемся изолировать Ваши\n" +
            "              личные данные от неидентифицирующей информации и обеспечим раздельное использование этих двух типов\n" +
            "              данных. Однако, если мы объединяем неидентифицирующую информацию с личной информацией, такая объединенная\n" +
            "              информация будет рассматриваться как личная информация до тех пор, пока она остается объединенной.",
        HowWeUseThePersonal: "2. Как мы используем собранную нами личную информацию",
        ThePurposeOfCollectingYour: "Целью сбора вашей личной информации является аутентификация пользователей нашего приложения и идентификация их\n" +
            "          забронированных, оплаченных билетов и истории их пассажиров.",
        ProvidingProcessingMaintaining: "Предоставление, обработку, обслуживание, улучшение и развитие наших продуктов и/или услуг для Вас.",
        PromotionOfTheAvioneApp: "Продвижение приложения Avione на рынке путем осуществления прямых контактов с Пользователями с помощью\n" +
            "              сетей связи",
        InternalPurposesSuchAs: "Внутренние цели, такие как анализ данных, исследование и разработка статистической информации, связанной с\n" +
            "              использованием наших продуктов и услуг для последующего улучшения их качества. Например, машинное обучение\n" +
            "              или модель обучения алгоритмов выполняется после деидентификации.",
        OptimizingOurProductsAnd: " Оптимизация наших продуктов и/или услуг(приложения Avione и веб-сайт https://www.avione.uz).",
        StoringAndMaintainingInformation: "Хранение и обслуживание связанной с Вами информации для наших деловых операций или для выполнения наших\n" +
            "              юридических обязательств.",
        HowWeShareTransfer: "3. Как мы делимся, передаем и публично раскрываем Вашу личную информацию",
        Sharing: "3.1. Совместное использование",
        WeDoNotSellYourPersonal: "Мы не продаем Вашу личную информацию сторонним лицам.",
        WeMayOccasionally: "Иногда мы можем передавать Вашу личную информацию сторонним лицам (как описано ниже) для предоставления или\n" +
            "          улучшения качества наших продуктов и услуг, включая предложение продуктов или услуг на основе Ваших\n" +
            "          требований. Дополнительная информация о совместном использовании данных изложена ниже.",
        SharingInformationAbout: "3.1.1. Передача сведений о том, что Вы активно выбираете или запрашиваете\n" +
            "          С Вашего согласия или по Вашему запросу мы поделимся Вашей личной информацией в рамках Вашего согласия/запроса\n" +
            "          с конкретными третьими лицами, обозначенными Вами.",
        SharingWithThirdParty: "3.1.2. Совместное использование со сторонними поставщиками услуг и деловыми партнерами\n" +
            "          Чтобы помочь нам предоставить Вам продукты и услуги, описанные в настоящей Политике конфиденциальности, мы\n" +
            "          можем при необходимости передавать Вашу личную информацию нашим сторонним поставщикам услуг и деловым\n" +
            "          партнерам.\n" +
            "          К ним относятся наши поставщики услуг доставки, центры обработки данных, хранилища данных, поставщики услуг\n" +
            "          для клиентов, поставщики рекламных и маркетинговых услуг и другие деловые партнеры.\n" +
            "          Мы стремимся к тому, чтобы передача личной информации, необходимой для предоставления Вам услуг,\n" +
            "          осуществлялась исключительно в легальных, законных, необходимых, конкретных и явных целях. Avione будет\n" +
            "          проводить комплексную проверку и заключать контракты, чтобы гарантировать, что сторонние поставщики услуг\n" +
            "          соблюдают применимые законы о конфиденциальности в Вашей юрисдикции.",
        ToProvidePerformanceMeasurement: "Для обеспечения измерения производительности, анализа и других бизнес-услуг мы также можем делиться\n" +
            "          информацией (неличной информацией) с третьими сторонами (такими как рекламодатели на наших веб-сайтах) в\n" +
            "          обобщенном виде. Мы используем имеющуюся у нас информацию для обеспечения помощи рекламодателям и другим\n" +
            "          деловым партнерам в оценке эффективности и охвата их рекламы и услуг, а также для понимания типов людей,\n" +
            "          которые используют их услуги, и того, как люди взаимодействуют с их веб-сайтами, приложениями и службами. Мы\n" +
            "          также можем делиться с ними общими тенденциями использования наших услуг, такими как количество клиентов в\n" +
            "          определенной группе людей",
        Broadcast: "3.2. Передача",
        AvioneWillNotShareYour: "Avione не будет передавать Вашу информацию какому-либо субъекту, за исключением следующих случаев:",
        IfWeHaveObtained: "Если мы получили Ваше явное согласие.",
        AtTheRequestOfCourt: "По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством\n" +
            "              процедуры",
        UnderTheCircumstancesDescribed: "При обстоятельствах, описанных в настоящей Политике конфиденциальности или в любом другом уведомлении.",
        ToProtectTheRightsAndLegitimate: "Для защиты прав и законных интересов Администратора в связи с нарушением заключенных с Пользователем\n" +
            "              договоров",
        PublicDisclosure: "3.3. Публичное раскрытие",
        AvioneMayPublicly: "Avione может публично раскрыть Вашу личную информацию при следующих обстоятельствах:",
        WhenWeNeedAnnounce: "• Когда нам нужно объявить победителя рекламной акции, конкурса или розыгрыша. В этом случае мы публикуем\n" +
            "              только ограниченную информацию.",
        IfWeHaveObtainedYour: "• Если мы получили Ваше явное согласие или если Вы раскрыли информацию через наши службы, например, на\n" +
            "              страницах в социальных сетях или на общедоступных форумах. Или",
        PublicDisclosureOfInformation: "• Публичное раскрытие информации на основании закона или уместных оснований: включая законы и\n" +
            "              постановления, юридические процедуры, судебные разбирательства или по запросу компетентных государственных\n" +
            "              ведомств.",
        Security: "4. Безопасность",
        TheAdministratorStoresPersonal: "Администратор хранит Персональные данные и обеспечивает их охрану от несанкционированного доступа и\n" +
            "          распространения в соответствии с внутренними правилами и регламентами. В отношении Персональных данных\n" +
            "          Пользователя сохраняется их конфиденциальность, за исключением случаев, когда технология Приложения либо\n" +
            "          настройки используемого Пользователем программного обеспечения предусматривают открытый обмен информацией с\n" +
            "          иными Пользователями приложения или с любыми пользователями сети Интернет.",
        PrivacyPolicyAndUse: "5. Политика конфиденциальности и использование персональной информации",
        ByUsingTheAvioneApps: "Используя приложения Avione, Вы подтверждаете, что Вы ознакомлены и согласны со всеми положениями и условиями,\n" +
            "          а также с политикой конфиденциальности, которая включает в себя использование персональной информации.\n" +
            "          Соглашение может быть изменено Администратором в одностороннем порядке без предварительного уведомления\n" +
            "          Пользователя. Новая редакция Соглашения вступает в силу с момента ее размещения по адресу\n" +
            "          https://test-frontend.avione.uz/privacy\n" +
            "          При внесении изменений в актуальной редакции указывается дата последнего обновления.",
        FinalProvisions: "6. Заключительные положения",
    },

    RailwayTariff: {
        ChooseCarriageAndSeat: "Выберите вагон и место",
        Price: "Цена от:",
        Carriage: "Вагон:",
        ChooseUpToFourSeat: "Выберите до 4 мест:",
        FreePlaces: "Свободные места",
        ChoosenCarriages: "Выбранный вагон",
        AirConditioner: "кондиционер",
        SelectedSeats: "Выбрано мест",
        TicketCost: "Стоимость билета",
        ServiceCost: "Стоимость услуги",
        TotalAmount: "Общая сумма"
    },

    Yes: "Есть",
    No: "Нет",

    Currency: 'сум',

    Enter: 'Войти',
    "not required": 'не обязательно'
}
