import {ref} from "vue";

export function requestData() {
    const requestId = ref<string>()
    return {
        requestId
    }
}

export const RequestData = requestData()
