
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "FlightBookingResult",
  props: {
    showAlert: Object as PropType<boolean>,
    orderNumber: Object as PropType<string>,
  },
  data() {
    return {
      bookingDate: ''
    }
  },
  watch: {
    showAlert(newValue) {
      if (newValue) {
        const now = new Date();
        now.setMinutes(now.getMinutes() + 30);

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        const formattedTime = `${hours}:${minutes}`;
        this.bookingDate = formattedTime
      }
    }
  },
  methods: {
    onCancelClick: function () {
      this.$emit("onCloseClickEvent");
    },
    onOkClick: function () {
      this.$emit("onOkClickEvent");
    },
  },
});
