import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header bg-gradient-to-r from-primary-lighter to-primary" }
const _hoisted_2 = { class: "container mx-auto" }
const _hoisted_3 = { class: "header__block py-10" }
const _hoisted_4 = { class: "main bg-body-light bg-opacity-50 pt-6 pb-20" }
const _hoisted_5 = { class: "container mx-auto" }
const _hoisted_6 = { class: "flex flex-wrap -mx-2 sm:-mx-4" }
const _hoisted_7 = { class: "w-full lg:w-9/12 px-2 sm:px-4 space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_NavigatorLine = _resolveComponent("NavigatorLine")!
  const _component_LeftMenu = _resolveComponent("LeftMenu")!
  const _component_FlightOrderDetailHeader = _resolveComponent("FlightOrderDetailHeader")!
  const _component_FlightOrderDetail = _resolveComponent("FlightOrderDetail")!
  const _component_FlightOrderPassengers = _resolveComponent("FlightOrderPassengers")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TheHeader)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NavigatorLine),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('PersonalArea.PersonalArea')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_LeftMenu),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FlightOrderDetailHeader),
            _createVNode(_component_FlightOrderDetail, { order: _ctx.order }, null, 8, ["order"]),
            _createVNode(_component_FlightOrderPassengers, { order: _ctx.order }, null, 8, ["order"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Loader, {
      message: _ctx.loadingMessage,
      show: _ctx.loading
    }, null, 8, ["message", "show"]),
    _createVNode(_component_TheFooter)
  ], 64))
}