<template>
  <ul class="container mx-auto flex items-center mb-9 space-x-2 text-body-muted">
    <li>
      <a class="text-primary hover:underline" href="#">{{ $t('home.main') }}</a>
      /
    </li>
    <li>
      <span>{{ $t('home.searching') }} / </span>
    </li>
    <li>
      <span>{{ $t('home.Checkout') }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "NavigatorLine"
}
</script>

