
import {defineComponent, PropType} from "vue";


export default defineComponent({
  name: "Alert",
  props: {
    'showAlert': Object as PropType<boolean>,
    'message': Object as PropType<string>,
    'showCancelBtn': Object as PropType<boolean>,
    'showOkBtn': Object as PropType<boolean>
  },
  methods: {
    onCancelClick: function () {
      this.$emit('onCloseClickEvent');
    },
    onOkClick: function () {
      this.$emit('onOkClickEvent');
    },
  }
})
;
