
import {defineComponent, onMounted, ref} from "vue";
import BalancePopup from "./BalancePopup.vue";
import {UserService} from "@/services/user_service";

export default defineComponent({
  name: "LeftMenu",
  components: {
    BalancePopup: BalancePopup,
  },

  setup() {
    const showBalancePopup = ref(false);
    const balance = ref({});
    onMounted(async () => {
      const data = await UserService.getUserBalance();
      balance.value = data;
    });

    return {
      showBalancePopup,
      balance,
    };
  },
});
