
import {computed, defineComponent, PropType, ref} from "vue";
import CheckAvailability from "@/components/CheckAvailability.vue";
import SearchResultItem from "@/components/Flight/SearchResultItem.vue";
import SearchResultMoreItem from "@/components/Flight/SearchResultMoreItem.vue";
import Alert from "@/components/General/Alert.vue";
import FlightFilterPanel from "@/components/Flight/FilterPanel.vue";
import {FlightSearchResult, Ticket} from "@/models/flight_models";
import {TicketOwner} from "@/constants/ticket_owner";
import {OfferService} from "@/services/offer_service";
import {ResponseStatusType} from "@/constants/response_status_type";
import {LocalDb} from "@/repository/localdb";
import {User} from "@/models/auth/user_models";
import {AuthService} from "@/services/auth_service";
import {AvioneMessage} from "@/constants/error_messages";
import Swal from "sweetalert2";

import router from "@/router";
import {useRouter, useRoute} from "vue-router";

const routers = useRouter();
const route = useRoute();
const currentUser = ref({} as User | null);
export default defineComponent({
  name: "SearchResult",
  props: {
    isOpen: Boolean,
    searchResult: {type: Object as PropType<FlightSearchResult>},
    isCharter: {type: Boolean, default: false},
  },
  data() {
    return {
      alertShow: false,
      showMoreDetails: false,
      showCheckAvailability: false,
      isFilterOpen: false,
      isUserLoggedIn: false,
      alertMessage: "",
      currentBuyId: "",
      showLoginForm: false,
      filteredTickets: {} as Ticket[],
      selectedTicket: {} as Ticket,
    };
  },
  // eslint-disable-next-line vue/order-in-components
  components: {
    CheckAvailability: CheckAvailability,
    SearchResultMoreItem: SearchResultMoreItem,
    SearchResultItem: SearchResultItem,
    FlightFilterPanel,
    Alert: Alert,
  },
  methods: {
    openFalse(e: any) {
      this.isFilterOpen = e;
    },
    onBuyClick: async function (ticket: Ticket) {
      this.logInUser();
      if (!this.isUserLoggedIn) {
        const result = await Swal.fire("", this.$t(AvioneMessage.CURRENT_USER_NOT_FOUND), "warning");
        if (result.isConfirmed) {
          router.push({
            path: this.$route.path,
            query: {showLoginForm: 'true'}
          });
        }

        return;
      }


      this.selectedTicket = ticket;
      this.showCheckAvailability = true;
      const body = this.isCharter
          ? {
            buyId: ticket.arrival?.buyId,
            ticketOwner: TicketOwner.CHARTER_AGENT,
            requiredTicketQty: 1,
          }
          : {
            buyId: ticket.arrival?.buyId,
            ticketOwner: TicketOwner.ETM,
          };
      await OfferService.getOfferAvailability(body).then((offerResponse) => {
        this.showCheckAvailability = false;
        if (offerResponse.status == ResponseStatusType.Ok) {
          if (offerResponse.data.availability && ticket.arrival) {
            LocalDb.saveBookingTicket({
              ticket: ticket,
              buyId: offerResponse.data.buyId,
              ticketOwner: this.isCharter
                  ? TicketOwner.CHARTER_AGENT
                  : TicketOwner.ETM,
            });

            router.push({
              name: "FlightBooking",
              params: {
                lang: this.$route.params.lang,
              },
            });
          } else {
            this.alertShow = true;
            this.alertMessage = offerResponse.data.message;
          }
        }
      });
    },
    onFilterChanged(data: Ticket[]): void {
      this.filteredTickets = data;
    },
    priceFormat(price: number): string {
      return price.toLocaleString("uz-UZ", {
        style: "currency",
        currency: "UZS",
        minimumFractionDigits: 0,
      });
    },
    onOkClickEventHandler: function () {
      this.alertShow = false;
      this.alertMessage = "";
    },
    logInUser: function () {
      currentUser.value = LocalDb.getCurrentUser();
      this.isUserLoggedIn = currentUser.value != null;
      this.showLoginForm = false;
    }
  },
});
