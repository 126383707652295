import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from "@/App.vue";

const routes: Array<RouteRecordRaw> = [{
  path: '/:lang(en|uz)?',
  component: App,
  children: [
    {
      path: '',
      name: 'Home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: 'railway',
      name: 'Railway',
      component: () => import('@/views/HomeRailway.vue')
    },
    {
      path: 'charter-flights',
      name: 'CharterFlights',
      component: () => import('@/views/FlightSearchCharter.vue')
    },
    {
      //http://localhost:8080/flight/direction=TAS20211123VKO-VKO20211126TAS&cls=E&adt=1&cld=0&inf=0
      path: 'flight/search/:searchParams',
      name: 'SearchResults',
      component: () => import('@/views/FlightSearchResult.vue')
    },
    {
      path: 'railway/search/:searchParams',
      name: 'RailwaySearchResults',
      component: () => import('@/views/RailwaySearchResult.vue')
    },
    {
      path: 'tours',
      name: 'Tours',
      component: () => import('../views/Tours.vue')
    },
    {
      path: 'profile/orders',
      name: 'ProfileOrders',
      component: () => import('@/views/Profile/FlightOrders.vue')
    },
    {
      path: 'flight/booking',
      name: 'FlightBooking',
      component: () => import('@/views/Flight/Booking.vue')
    },
    {
      path: 'railway/booking',
      name: 'RailwayBooking',
      component: () => import('@/views/Railway/Booking.vue')
    },
    {
      path: 'flight/payment/:id',
      name: 'FlightBookingPayment',
      component: () => import('@/views/Flight/Payment.vue')
    },
    {
      path: 'railway/payment/:id',
      name: 'RailwayBookingPayment',
      component: () => import('@/views/Railway/Payment.vue')
    },
    {
      //http://localhost:8080/profile/orders/123
      path: 'profile/orders/:id',
      name: 'FlightOrderDetails',
      component: () => import('@/views/Profile/FlightOrderDetails.vue')
    },
    {
      //http://localhost:8080/flight/payment/confirm/123
      path: 'flight/payment/confirm/:id',
      name: 'PaymentConfirm',
      component: () => import('@/views/Flight/PaymentConfirm.vue')
    },
    {
      path: 'profile/passengers',
      name: 'FlightPassengers',
      component: () => import('@/views/Profile/FlightPassengers.vue')
    },
    {
      path: 'about',
      name: 'About',
      component: () => import('../views/CompanyAbout.vue')
    },
    {
      path: 'public-offer',
      name: 'PublicOffer',
      component: () => import('../views/CompanyPublicOffer.vue')
    },
    {
      path: 'privacy',
      name: 'Privacy',
      component: () => import('../views/CompanyPrivacy.vue')
    },
    {
      path: 'direction/:fromCode/:toCode',
      name: 'FlightDirection',
      component: () => import('../views/FlightDirections.vue'),
      props: true
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('../views/NotFound.vue')
    },

  ]
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0,
        behavior: 'smooth',
      }
    }
  },
})

export default router
