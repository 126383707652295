
import {defineComponent, PropType} from "vue";
import {User} from "@/models/auth/user_models";

export default defineComponent({
  name: "FlightBookingContactInfo",
  props: {
    "user": {type: Object as PropType<User>}
  },
  computed: {
    userPhoneNumber: function (): string {
      if (this.user) {
        return '+'.concat(this.user.phoneNumberCode, this.user.phoneNumber);
      }
      return '';
    }
  },
});
