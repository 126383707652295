import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header bg-gradient-to-r from-primary-lighter to-primary" }
const _hoisted_2 = { class: "container mx-auto" }
const _hoisted_3 = { class: "header__block py-10" }
const _hoisted_4 = { class: "main bg-body-light pb-20" }
const _hoisted_5 = { class: "container mx-auto" }
const _hoisted_6 = { class: "flex flex-wrap -mx-4" }
const _hoisted_7 = { class: "w-full lg:w-9/12 px-4 space-y-8" }
const _hoisted_8 = { class: "p-4 sm:p-6 bg-white rounded-lg border border-opacity-70" }
const _hoisted_9 = { class: "-mx-2 sm:-mx-4 flex flex-wrap items-center" }
const _hoisted_10 = { class: "font-bold mx-2 sm:mx-4 text-2xl font-light text-primary flex-auto text-center lining-nums" }
const _hoisted_11 = { class: "w-full mt-8 lg:mt-0 lg:w-3/12 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_FlightBookingSteps = _resolveComponent("FlightBookingSteps")!
  const _component_NavigatorLine = _resolveComponent("NavigatorLine")!
  const _component_FlightPaymentDetail = _resolveComponent("FlightPaymentDetail")!
  const _component_FlightBookingContactInfo = _resolveComponent("FlightBookingContactInfo")!
  const _component_FlightBookingPassengerList = _resolveComponent("FlightBookingPassengerList")!
  const _component_FlightBookingPaymentSelect = _resolveComponent("FlightBookingPaymentSelect")!
  const _component_FlightPaymentExtraInfo = _resolveComponent("FlightPaymentExtraInfo")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_FlightBookingPaymentPriceChange = _resolveComponent("FlightBookingPaymentPriceChange")!
  const _component_PaymentForm = _resolveComponent("PaymentForm")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TheHeader)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FlightBookingSteps, { "active-tab": "2" }),
      _createVNode(_component_NavigatorLine),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FlightPaymentDetail, { ticket: _ctx.orderData }, null, 8, ["ticket"]),
            _createVNode(_component_FlightBookingContactInfo, { user: _ctx.currentUser }, null, 8, ["user"]),
            _createVNode(_component_FlightBookingPassengerList, { passengers: _ctx.passengers }, null, 8, ["passengers"]),
            _createVNode(_component_FlightBookingPaymentSelect, { onOnPaymentTypeChangeEvent: _ctx.onPaymentTypeChangeEventHandler }, null, 8, ["onOnPaymentTypeChangeEvent"]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  class: "p-3 mx-2 sm:mx-4 flex-auto rounded border hover:text-white hover:bg-primary hover:border-transparent transition",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancelPaymentRequest && _ctx.onCancelPaymentRequest(...args)))
                }, _toDisplayString(_ctx.$t("booking_result_popup.Later")), 1),
                _createElementVNode("button", {
                  class: "p-3 mx-2 sm:mx-4 flex-auto rounded flex items-center justify-center text-white bg-primary space-x-3 hover:bg-opacity-90 transition",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPaymentClickEventHandler && _ctx.onPaymentClickEventHandler(...args)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Basic.GoToThePayment")), 1)
                ]),
                _createElementVNode("strong", _hoisted_10, _toDisplayString(_ctx.orderData.amountFormatted), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_FlightPaymentExtraInfo, { ticket: _ctx.orderData }, null, 8, ["ticket"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Loader, {
      message: _ctx.$t(_ctx.loadingMessage),
      show: _ctx.loading
    }, null, 8, ["message", "show"]),
    _createVNode(_component_FlightBookingPaymentPriceChange, {
      "payment-info": _ctx.paymentData,
      "show-message": _ctx.priceChangeShow,
      onOnCloseClickEvent: _ctx.onClosePriceChangeClickEvent,
      onOnOkClickEvent: _ctx.onContinuePriceChangeClickEventHandler
    }, null, 8, ["payment-info", "show-message", "onOnCloseClickEvent", "onOnOkClickEvent"]),
    _createVNode(_component_PaymentForm, {
      "is-open": _ctx.openPaymentPopup,
      "payment-url": _ctx.paymentUrl,
      onOnPaymentFormCloseEvent: _ctx.onPaymentFormCloseEventHandler
    }, null, 8, ["is-open", "payment-url", "onOnPaymentFormCloseEvent"]),
    _createVNode(_component_TheFooter)
  ], 64))
}