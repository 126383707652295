
import {defineComponent, PropType} from "vue";
import FlightOrderListItem from "@/components/Profile/FlightOrderListItem.vue";
import RailwayOrderListItem from "@/components/Profile/RailwayOrderListItem.vue";
import Loader from "@/components/General/Loader.vue";
import {OrderData} from "@/models/stored_data_models";
import profileOrderController from "@/composables/profile_order_list";
import {UserService} from "@/services/user_service";
import {FlightOrderStatus} from "@/models/flight_order_status";
import {AppUserOrdersService} from "@/services/app_user_orders";
import samalyot from "@/assets/img/svgComponent/samalyot.vue";
import poyezd from "@/assets/img/svgComponent/poyezd.vue";
import {LocalDb} from "@/repository/localdb";

export default defineComponent({
  name: "OrderList",
  props: {
    // 'paidOrders': {type: Object as PropType<OrderData[]>},
    // 'bookedOrders': {type: Object as PropType<OrderData[]>},
  },
  components: {
    FlightOrderListItem: FlightOrderListItem,
    Loader: Loader,
    RailwayOrderListItem: RailwayOrderListItem,
    // poyezd,
    // samalyot
  },
  data() {
    return {
      currentTab: "bookingTab",
      rail: false,
      air: true,
      orderPage: {
        page: 1,
        limit: 5,
        status: "B",
      },
      count: 0,
      railwayData: [] as any,
      data: [] as OrderData[],
    };
  },
  computed: {
    payOrders(): OrderData[] | any {
      return this.data
          .filter((s) => s.status === FlightOrderStatus.PAID)
          .sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
    },
    bookOrders(): OrderData[] | any {
      return this.data
          .filter((s) => s.status === FlightOrderStatus.BOOKED)
          .sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
    },
    voids(): OrderData[] | any {
      return this.data
          .filter((s) => s.status === FlightOrderStatus.VIOD)
          .sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
    },
  },

  methods: {
    airLineOrder() {
      this.rail = false;
      this.air = true;
      this.myOrders();
    },
    railwayOrder() {
      this.rail = true;
      this.air = false;
      this.myOrdersRailway();
    },
    handleSizeChange(val: number) {
      this.getData();
    },
    // Paginationda joriy sahifa almashishi
    handleCurrentChange(val: number) {
      this.getData();
    },
    // Paginationda keyingi sahifaga o'tish tugmasi bosilgan holat
    handleNextClick(val: number) {
      this.getData();
    },

    // Paginationda olding sahifaga o'tish tugmasi bosilgan holat
    handlePrevClick(val: number) {
      this.getData();
    },
    handleSizeChangeR(val: number) {
      this.getRailwayData();
    },
    // Paginationda joriy sahifa almashishi
    handleCurrentChangeR(val: number) {
      this.getRailwayData();
    },
    // Paginationda keyingi sahifaga o'tish tugmasi bosilgan holat
    handleNextClickR(val: number) {
      this.getRailwayData();
    },

    // Paginationda olding sahifaga o'tish tugmasi bosilgan holat
    handlePrevClickR(val: number) {
      this.getRailwayData();
    },
    myOrders() {
      this.orderPage.page = 1;
      this.orderPage.status = "T";
      this.getData();
      this.currentTab = "ordersTab";
    },
    myBooking() {
      this.orderPage.page = 1;
      this.orderPage.status = "B";
      this.getData();
      this.currentTab = "bookingTab";
    },
    myVoid() {
      this.orderPage.page = 1;
      this.orderPage.status = "V";
      this.getData();
      this.currentTab = "void";
    },
    myOrdersRailway() {
      this.orderPage.status = "T";
      this.getRailwayData();
      this.currentTab = "railwayOrdersTab";
    },
    myBookingRailway() {
      this.orderPage.status = "B";
      this.getRailwayData();
      this.currentTab = "railwayBookingTab";
    },
    myVoidRailway() {
      this.orderPage.status = "V";
      this.getRailwayData();
      this.currentTab = "railwayVoid";
    },

    async getData() {
      const data = await UserService.getOrdersWithPagable(this.orderPage);
      this.data = data.data;
      this.count = data.count;
    },
    async getRailwayData() {
      const data = await AppUserOrdersService.getAppUserOrders(this.orderPage);
      this.railwayData = data;
      this.count = this.railwayData?.count;
      const railwayOrders = LocalDb.getMyFlightOrders();

      this.railwayData?.orders?.map((order: any) => {
        const isExist = railwayOrders!.find(
            (railwayOrder) => railwayOrder.orderId === order.orderId
        );
        if (isExist) {
          return;
        } else {
          LocalDb.saveMyFlightOrder(order);
        }
      });
    },
  },
  setup() {
    const {
      state,
      onLoadStoredData,
      onDeleteOrder,
      wantToDeleteOrderCancel,
    } = profileOrderController();
    return {
      ...state,
      onLoadStoredData,
      onDeleteOrder,
      wantToDeleteOrderCancel,
    };
  },

  created() {
    this.getRailwayData();
    this.getData();
    this.onLoadStoredData();
  },
});
