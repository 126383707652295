import {useHead} from "@vueuse/head";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";


export function usehead(){
    const { t } = useI18n({ useScope: 'global' });
    const route = useRoute()
    const router = useRouter()
    const currentUrl = computed(() => router.resolve({
        name: 'Home',
        params: {lang: route.params.lang}
    }).href)

    useHead({
        title: computed(() => t('pages.home.title')),
        meta: [
            {
                name: `description`,
                content: computed(() => t('pages.home.description')),
            },
            {
                name: `og:title`,
                content: computed(() => t('pages.home.title')),
            },
            {
                name: `og:description`,
                content: computed(() => t('pages.home.description')),
            },
            {
                name: `og:type`,
                content: 'website',
            },
            {
                name: `og:url`,
                content: computed(() => `https://avione.uz${currentUrl.value}`),
            },
        ],

        link: computed(() => [
            {
                rel: 'canonical',
                href: `https://avione.uz${currentUrl.value}`
            }
        ].concat([
            {lang: 'uz', prefix: 'uz'},
            {lang: 'en', prefix: 'en'},
            {lang: 'ru', prefix: ''},
        ].filter((l) => route.params.lang !== l.prefix).map((l) => {
            return {
                rel: 'alternate',
                hreflang: l.lang,
                href: 'https://avione.uz' + router.resolve({
                    name: 'Home',
                    params: {lang: l.prefix}
                }).href
            }
        })))
    })
    return{
        useHead
    }
}
