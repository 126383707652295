
import {defineComponent, onMounted, PropType} from "vue";
import {
  CollectionResponse,
  DocumentType,
  PassengerTypeQty,
  Region,
} from "@/models/collection_models";
import {Country, Gender} from "@/models/auth/user_models";
import {LocalDb} from "@/repository/localdb";
import flight_booking_store from "@/composables/flight_booking";
import "vue-inputmask";
import {uz} from "date-fns/locale";
import {UserService} from "@/services/user_service";
import {Passenger} from "@/models/order_modes";
import moment from "moment";
import InputDate from "../InputDate.vue";

export default defineComponent({
  name: "FlightBookingPassengerForm",
  components: {InputDate},
  props: {
    passengerTypeQty: {type: Object as PropType<PassengerTypeQty[]>},
    validate: {type: Object as PropType<boolean>}
  },
  setup() {
    const {state} = flight_booking_store();

    onMounted(() => {
      console.log('state', state);
    });
    return {...state};
  },
  data() {
    return {
      selectedPassenger: [] as any,
      list: [] as any,
      isUzb: false,
      passengerPage: {
        page: 1,
        limit: 5,
        search: "" as string | undefined,
      },
      documentTypes: {} as DocumentType[],
      regions: {} as Region[],
      genders: {} as Gender[],
      countries: {} as Country[],
      data: [] as Passenger[] | null,
      passengersData: [] as Passenger[] | null,
    };
  },
  mounted() {

    this.passengers.forEach((passenger) => {
      this.selectedPassenger.push({id: null});

      if (!passenger.document.type?.name) {
        if (passenger.document.type) passenger.document.type.name = 'Заграничный паспорт'
        this.onDocumentTypeChange(passenger)
      }

      if (!passenger.citizenCode) {
        passenger.citizenCode = 'UZB'
        this.onCitizenshipChange(passenger)
      }

      if (!passenger.gender?.name) {
        passenger.gender = {
          "code": "M",
          "name": "Мужчина",
          "isDefault": false,
          "orderNumber": 0
        }
      }

      if (!passenger?.regionId) {
        passenger.regionId = '03'
        passenger.document.regionId = '03'
      }


    });
  },
  methods: {
    onDocumentTypeChange(passenger: any) {
      const selectingType = this.documentTypes.find(
          (types) => passenger.document.type.name === types.name
      );
      passenger.document.type = selectingType;
    },

    onRegionChange(passenger: any) {
      passenger.document.regionId = passenger.regionId;
    },

    onPassengerBirthDateChange(passenger: any) {
      const passengerAge = moment().diff(passenger.birthDate, "years");
      if (passengerAge <= 10) {
        passenger.type = {
          code: "CHD",
          name: "Дети",
          description: "(2-11)",
          isDefault: false,
          orderNumber: 2,
        };
      } else {
        passenger.type = {
          code: "ADT",
          name: "Взрослый",
          description: "(12 и старше)",
          isDefault: true,
          orderNumber: 1,
        };
      }
    },

    onGenderChange(passenger: any) {
      const selectingGender = this.genders.find(
          (gender) => passenger.gender.name === gender.name
      );
      passenger.gender = JSON.parse(JSON.stringify(selectingGender));
    },

    onCitizenshipChange(passenger: any) {
      const selectingCitizenship = this.countries.find(
          (country: any) => country.citizenshipCode === passenger.citizenCode
      );
      passenger.citizenship = selectingCitizenship;
      if (passenger.citizenCode == 'UZB')
        this.isUzb = true;
      else {
        this.isUzb = false;
        passenger.regionId = '';
      }
    },

    passengerEvent(passengerId: any, passenger: any) {
      const data = this.passengersData?.find(
          (item: any) => item.id === passengerId
      );
      if (data) {
        passenger.type.name = data.type.name;
        passenger.type.code = data.type.code;
        passenger.appPassengerId = data.appPassengerId;
        passenger.firstName = data.firstName;
        passenger.lastName = data.lastName;
        passenger.middleName = data.middleName;
        passenger.email = data.email;
        passenger.phoneNumber =
            (data.phoneNumberInfo?.phone as string) + data.phoneNumber;

        passenger.document = data.document;
        passenger.citizenship = data.citizenship;
        passenger.birthDate = data.birthDate;
        passenger.gender = data.gender;
      }
    },
    async getData(search?: string) {
      this.passengerPage.search = search;
      const data = await UserService.getPassengerWithPagable(
          this.passengerPage
      );

      this.passengersData = data.data;
    },
    getPhoneNumber(passenger: any) {
      return (passenger.phoneNumberInfo?.phone as string) + passenger.phoneNumber;
    }
  },
  created() {
    this.getData(this.passengerPage.search as string);
    const collections: CollectionResponse | null = LocalDb.getCollections();
    if (collections) {
      this.documentTypes = collections.documentTypes;
      this.genders = collections.genders;
      this.countries = collections.countries;
      this.regions = collections.regions;
    }
  },
  computed: {
    locale: () => uz,
  },
});
