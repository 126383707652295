import {FlightClass, PassengerType} from "@/models/flight";

export default {
    flightClass: {
        [FlightClass.Economy]: 'Ekonom',
        [FlightClass.Business]: 'Biznes',
    },

    searchPanel: {
        yourMessageIsOutOf: 'Sizdagi ma\'lumotlar eskirgan Yangilash tugmasini bosing',
        cancel: 'Bekor qilish',
        Update: 'Yangilash',
        errors: {
            selectFromLocation: 'Qayerdan ketish nuqtasini tanlang',
            selectToLocation: 'Borish manzilini tanlang',
            selectFromDate: 'Ketish sanasini tanlang',
            selectToDate: 'Borish sanasini tanlang',
        },
        there: "Borish",
        backAndForth: "Borib qaytish"
    },

    directions: {
        title: "Yo'nalishlar",
        popular: "Mashhur yo'nalishlar",
        isPopular: {
            yes: 'Популярность данного направления остается неизменно высокой.',
            no: 'Данное направление имеет умеренную популярность.',
        },
        isTheSameCountry: {
            yes: 'соединяет два города в пределах одного государства.',
            no: 'соединяет два города в разных государствах.',
        },
        locations: {
            tas: {
                name: "Toshkent",
                genitiveCase: "Toshkent",
            },
            mow: {
                name: "Moskva",
                genitiveCase: "Moskva",
            },
            ist: {
                name: "Istambul",
                genitiveCase: "Istambul",
            },
            cit: {
                name: "Chimkent",
                genitiveCase: "Chimkent",
            },
            kzn: {
                name: "Qozon",
                genitiveCase: "Qozon",
            },
            ugc: {
                name: "Urganch",
                genitiveCase: "Urganch",
            },
            bhk: {
                name: "Buxoro",
                genitiveCase: "Buxoro",
            },
            skd: {
                name: "Samarqand",
                genitiveCase: "Samarqand",
            },
            nma: {
                name: "Namangan",
                genitiveCase: "Namangan",
            },
            feg: {
                name: "Farg'ona",
                genitiveCase: "Farg'ona",
            },
            oss: {
                name: "O'sh",
                genitiveCase: "O'sh",
            },
            tmj: {
                name: "Termiz",
                genitiveCase: "Termiz",
            },
            led: {
                name: "Sankt-Peterburg",
                genitiveCase: "Sankt-Peterburg",
            },
            ovb: {
                name: "Novosibirsk",
                genitiveCase: "Novosibirsk",
            },
            ikt: {
                name: "Irkutsk",
                genitiveCase: "Irkutsk",
            },
            svx: {
                name: "Yekaterinburg",
                genitiveCase: "Yekaterinburg",
            },
            aer: {
                name: "Sochi",
                genitiveCase: "Sochi",
            },
            ala: {
                name: "Olma-ota",
                genitiveCase: "Olma-ota",
            },
            ufa: {
                name: "Ufa",
                genitiveCase: "Ufa",
            },
            kuf: {
                name: "Samara",
                genitiveCase: "Samara",
            },
            dme: {
                name: "Domodedovo",
                genitiveCase: "Domodedovo",
            },
            vko: {
                name: "Vnukovo",
                genitiveCase: "Vnukovo",
            },
            kej: {
                name: "Kemerovo",
                genitiveCase: "Kemerovo",
            },
            kja: {
                name: "Krasnoyarsk",
                genitiveCase: "Krasnoyarsk",
            },
            oms: {
                name: "Omsk",
                genitiveCase: "Omsk",
            },
            pee: {
                name: "Perm",
                genitiveCase: "Perm",
            },
            mcx: {
                name: "Mahachkala",
                genitiveCase: "Mahachkala",
            },
            ksq: {
                name: "Qarshi",
                genitiveCase: "Qarshi",
            },
            krr: {
                name: "Krasnodar",
                genitiveCase: "Krasnodar",
            },
        }
    },
    topRoutes: {
        title: '{fromGen}dan uchadigan mashhur parvozlar',
        titleWithoutLocation: 'Mashhur parvozlar',
        leadText: 'Parvoz manzillari',
    },

    countries: {
        russia: 'Rossiya',
        uzbekistan: 'O\'zbekiston',
        kazakhstan: 'Qozog\'iston',
        turkey: 'Turkiya',
    },

    pages: {
        type: 'Turlar',
        home: {
            title: 'Aviachipta qidirish va arzon narxlarda xarid qilish. Toshkent va boshqa shaharlardan parvozlar.',
            shortTitle: 'Toshkentda aviachiptalarni xarid qiling.',
            titleH1: 'Aviachipta qidirish va bron qilish. Dunyo bo\'ylab parvozlar uchun qulay narxlar.',
            titleH2: 'O\'zbekiston bo\'ylab hamyonbop narxda sayohat qiling!',
            description: 'Dunyoning turli aviakompaniya va aviakassalaridan chipta qidirish va sotib olish. Toshkent, Moskva, Urganch, shuningdek O\'zbekiston va Rossiyaning boshqa shaharlaridan to\'g\'ridan to\'g\'ri parvozlar uchun biletlar.',
            leadText: `Bizning veb-saytimizda aviachiptalarni xarid qilish sayohatingizni rejalashtirishning qulay usuli hisoblanadi.
           Chiptalarni sotib olish uchun bizning onlayn xizmatimizdan foydalanish mantiqan.
           Shunday qilib, siz ham vaqtni, ham pulni tejashingiz mumkin.`,
            content: `<h2 style="margin: 1rem 0;">Chiptaning narxi qancha?</h2>
            <p style="margin: .65rem 0;">
              Aviachipta narxi doimiy qiymatga ega emas.
               Narxlar mavsumiylik, ma'lum bir sanada ushbu yo'nalishga bo'lgan talab va boshqa omillar asosida shakllanadi.
               Tariflar quyidagi parametrlarni hisobga olgan holda hisoblanadi:
            </p>
            <ul style="padding-left: 2rem;margin: 1rem;list-style: disc;">
              <li>Hafta kuni.</li>
              <li>Uchish soati.</li>
              <li>Ulanishlar mavjudligi.</li>
            </ul>
            <p style="margin: .65rem 0;">
              Dam olish va bayram kunlarida parvozlar narxi an'anaviy ravishda yuqoriroq.
               Ushbu sanalarda reyslarga talab yuqori.
            </p>
            <p style="margin: .65rem 0;">
              Ertalab aviachiptalar narxi qimmatroq.
               Buning sababi ish bo'yicha sayohat qiladiganlar tomonidan reyslarga talabning ortishi bilan bog'liq.
               Faqat bir tomonga bilet xarid qilgandan ko'ra, borish-kelish uchun xarid qilish 
               foydaliroq bo'ladi. Albatta bu narsa qaytish sanasi aniq bo'lsagina foydali. 
               Shu bilan birga, tungi reyslar odatda arzonroq.
            </p>
            <p style="margin: .65rem 0;">
              Ko'p narsa ulanishlar (peresadka) mavjudligiga bog'liq. 
              6-8 soatdan uzoq ulanishga ega chiptalar arzonroq.
               Ulanishlarsiz sayohat uchun siz ko'proq pul to'lashingiz kerak bo'lishi mumkin.
               To'g'ridan-to'g'ri reyslar vaqtni tejash nuqtai nazaridan qulayroqdir. 
               Bu maqsadingizga erishishning eng tez yo'li. 
               Atigi 4 soatda siz deyarli 3 ming kilometr masofani bosib o'ta olasiz.
            </p>
            <p style="margin: .65rem 0;">
              Aviachiptalar narxi samolyotning joylarini to'lishiga, 
              mavjud aksiyalarga, chegirmalarga bog'liq.
               Jo'nash kunigacha qancha kamroq o'rindiq qolsa, sayohat shunchalik qimmatga tushadi.
            </p>`,
        },
        directions: {
            title: '{from} — {to} yo\'nalishidagi parvozlar uchun aviachiptalar. {from}dan qulay narxlarda bilet xarid qiling.',
            titleH1: '{from} — {to} yo\'nalishidagi parvozlar uchun aviachiptalar. {from}dan qulay narxlarda parvoz qiling.',
            description: '{from}dan {to}ga arzon narxlarda aviachiptalarga buyurtma berish. Turli aviakompaniyalarning narxlarini taqqoslash. Bir yoki ikkala tomonga parvoz uchun chipta xarid qilish. {from} shahridan {to}ga uchadigan samolyotlar uchun bilet qidirish.',
            content: `<h2 style="margin-bottom: 1rem;">
                  {fromGen}dan {to}ga aviachiptalarni bron qilish va sotib olish
                </h2>
                <p style="margin: .65rem 0;">
                  2022 yilda tariflarni belgilashning odatiy qoidalari ba'zi o'zgarishlarga duch 
                  kelgan bo'lishi mumkin.
                   Parvozning narxi, valyuta kursi va boshqa omillarga bog'liq.
                  { popularText }
                  Shuning uchun, belgilangan yo'nalish bo'yicha samolyotga chiptalarni 
                  oldindan sotib olish maqsadga muvofiq bo'ladi.
                </p>
                <h3 style="margin: 1rem 0;">
                  {fromGen} — {to} parvozlarining mashhurligi
                </h3>
                <p style="margin: .65rem 0;">
                  {fromGen}dan {to}gacha boʻlgan parvoz yoʻnalishi ikkita shaharni bogʻlaydi.
                  Bu yo'nalishda havo qatnovi yaxshi rivojlangan.
                  Har kuni bir nechta aviatashuvchilarning samolyotlari aeroportlar o'rtasida parvoz qiladi.
                  Pulni tejash uchun chiptalarni oldindan sotib olish ma'qulroq.
                  Eng arzon narxda, odatda, jo'nashdan 2 oy oldin bron qilish mumkin.
                  Chipta narxi jo'nash sanasiga yaqinroq oshadi.
                  Muayyan oylarda, ushbu yo'nalish bo'yicha parvoz narxi o'zgarishi mumkin - ham o'sish, ham pasayish.
                  Odatda eng arzon takliflar birinchi navbatda sotiladi.
                  Biroq, ba'zida chiptani jo'nashdan bir kun oldin ham arzon narxda sotib olishingiz mumkin.
                </p>
                <p style="margin: .65rem 0;">
                  Sahifada parvozlar uchun barcha mavjud takliflar mavjud.
                   Qidiruv natijalarida keltirilgan barcha parvozlar rejalashtirilgan.
                   Aviachiptalarni belgilangan parametrlar bo'yicha filtrlash mumkin.
                   Qulay qidiruv tizimi sizga kerakli biletni bron qilishingizda yordam beradi.
                   Siz faqat kerakli jo'nash sanasini, yo'lovchilar sonini, parvoz sinfini (klassini) ko'rsatishingiz kerak.
                </p>`
        },
        charterFlights: {
            title: 'Charter reyslar',
            notFound: 'Afsuski, hozircha charter reyslari mavjud emas.',
        }
    },

    title: {
        titleLang: "Avione.uz - Hamyonbop aviachiptalar"
    },
    Мужчина: "Erkak",
    Женщина: "Ayol",

    search: {
        Search: "Qidirish...",
        fromLocation: "Qayerdan",
        toLocation: "Qayerga",
        button: "Qidiruv",
        Back: "Qaytish",
        There: "Ketish",
        passengers: "yo'lovchilar",
        Passengers: "Yo'lovchilar",
        ChoosePlaceOfArrival: "Kelish joyini tanlang",
        SelectYourDeparturePoint: "Ketish nuqtasini tanlang",
        allAirports: "barcha aeroportlar"
    },
    search_result: {
        options: "variantlar",
        Filters: "Filtrlar",
        TheCheapest: "Eng arzon",
        Buy: "Sotib olish",
        FlightDetails: "Parvoz tafsilotlari",
        CollapseDetails: "Tafsilotlarni yig'ish",
        OnMyWay: "Davomiyligi:",
        Transplants: "O'tish:",
        PieceOfHandLuggage: "1 dona qo'l yuki",
        TransferTo: "o'tkazish",
        OnTheWay: 'Yo\'l',
        ChooseTrain: 'poezd tanlash',
        NotLeftSeat: 'joy qolmagan'
    },
    booking_result_popup: {
        BookingResult: "Buyurtma natijasi",
        YourBooking: "Sizning buyurtma raqamingiz №",
        createdSuccessfully: " muvaffaqiyatli yaratilgan.",
        ValidityOfYourBooking: "Buyurtmaning amal qilish muddati - {date} gacha",
        Later: "Keyinchalik",
        Pay: "To'lash"
    },
    contact_info: {
        RecipientContacts: "Qabul qiluvchining kontaktlari",
        Phone: "Telefon",
        Email: "Elektron pochta",
        WeWillSendTicketsInformAbout: "Biz chiptalarni yuboramiz, sayohatdagi o'zgarishlar haqida xabar beramiz. Agar sizga qo'shimcha ma'lumot kerak bo'lsa, biz siz bilan bog'lanamiz."
    },
    extra_info: {
        Data: "Ma'lumotlar",
        HandLuggage: "Qo'l yuki: 1 ta sumka",
        Luggage: "Bagaj:",
        Adult: "1 kattalar"
    },
    home: {
        flights: "Parvozlar",
        Hotels: "Mehmonxonalar",
        Welcome: "Xush kelibsiz",
        tell: "Biz sizga aytamiz, qayerda dam olishingiz mumkinligini ko'rsatamiz",
        show: "Biz sizga aytamiz, aytamiz va qayerda dam olishingiz mumkinligini ko'rsatamiz",
        bonuses: "Mobil ilovadan foydalanib, siz veb-saytdagidan deyarli 2 barobar ko'proq bonuslarga ega bo'lasiz.",
        main: "Asosiy",
        searching: "Qidiruv natijalari",
        Checkout: "Buyurtma",
        variant: "Variantlari",
    },
    About: {
        AboutUs: "Biz haqimizda",
        newMobileApplication: "Avione – O‘zbekistondagi yangi mobil ilova.  Bu yerda siz bir necha soniya ichida reyslarni topishingiz, narxlarni o'zingiz solishtirishingiz va xarid qilishingiz mumkin. Qidirish, 24/7 onlayn qo'llab-quvvatlash bilan aviachiptalarni taqqoslash va sotib olish",
        BuyAirTickets: "Aviachiptalarni bir necha daqiqada dunyoning istalgan nuqtasiga, samolyot chiptalarini sotib olingAeroflot aviakompaniyasi,Pobeda, S7 (s7, Sibir), Yamal, Ural Airlines, Utair (UTair), Air Baltic, arzon Air Berlin,Lufthansa, Air France, Emirates, British Airways va boshqa ko'plab kompaniyalar bitta ilovada.",
        searchForAirTickets: "Aviachiptalarni qidirish kunning istalgan vaqtida onlayn rejimda amalga oshiriladi. Endi ofisga borish shart emaschipta kassasi. Chipta to'g'ridan-to'g'ri Avione ilovangizga va elektron pochtangizga keladi, shunchaki kerak chop eting va o'zingiz bilan aeroportga olib boring.",
        ItISBetterTSearch: "Kerakli jo'nash sanasidan 4-6 oy oldin aviachiptalarni qidirish va bron qilish yaxshiroqdir. Buning uchun foydalaning\n" +
            "         700 dan ortiq aviakompaniyalarning takliflarini o'z ichiga olgan Avione ilovasi. Bu erda siz osongina mumkin\n" +
            "         xarajatlar dinamikasini, jo'nash vaqti va kunini kuzatib boring va natijada Internet orqali samolyot chiptasiga buyurtma bering.",
        yearsOld: "12 yoshda",
        MarketExperience: "Bozor tajribasi",
        Узбекистана: "Uzbekistan",
        SatisfiedCustomers: "Qoniqarli mijozlar",
        AirlinesFor: "aviakompaniyalar uchun",
        ticketSearch: "chipta qidirish",
        CountriesSearch: "Qidiriladigan mamlakatlar",
        hotels: "mehmonxonalar",
        HowBuyTicketThroughAvione: "Avione orqali chiptani qanday sotib olish mumkin:",
        OpenAvioneApp: "Avione ilovasini oching",
        SpecifyTheTimeCityOf: "Vaqtni, ketish va kelish shahrini belgilang",
        YouCanUseFiltersChooseTicket: "Siz filtrlardan foydalanishingiz va juda ko'p turli xillarga mos keladigan chiptani tanlashingiz mumkin",
        ChooseThePlaneTickets: "Sizga mos keladigan samolyot chiptalarini tanlang",
        CreateAccountTheAvioneApp: "Avione ilovasida hisob yarating",
        EnterPassengerDetails: "Yo'lovchi ma'lumotlarini kiriting va aviachiptalarni onlayn to'lang",
        TheFlightTicketWillCome: "Parvoz chiptasi to'g'ridan-to'g'ri Avione ilovangizga va elektron pochtangizga keladi.",
        AirfarePricesIncludingFees: "Aviachiptalar narxlari, shu jumladan barcha to'lovlar",
        AllFlightPricesFinal: "Barcha parvoz narxlari yakuniy hisoblanadi. Sotib olishdan keyingi to'lovlar yo'q",
        ToReturnTheTicket: "Chiptani qaytarish uchun siz bizning qo'llab-quvvatlash guruhimizga murojaat qilishingiz mumkin, ular to'lovni tezda qaytarishga yordam beradi",
        Support: "Qo'llab-quvvatlash xizmati",
        OurSuportCovers: "Bizning qo'llab-quvvatlashimiz foydalanuvchilarga 24/7 kun davomida yordam berishga qaratilgan xizmatlarning to'liq spektrini o'z ichiga oladi so'rovga foydali javob oling. Istalgan vaqtda bizga yozing yoki qo'ng'iroq qiling, biz sizga yordam berishdan xursand bo'lamiz!",
        OurContacts: "Bizning kontaktlarimiz",
        Thanks: "Rahmat,",
        WeHappyHelpYou: "Sizga yordam berishdan xursandmiz. Avion jamoasi.",
        AdditionalServices: "Qo'shimcha xizmatlar",
        WithUsTheTripMoreComfortable: "Biz bilan sayohat yanada qulay va xavfsizroq bo'ladi, chunki biz parvoz sug'urtasini tashkil qilishimiz mumkin, salonda joyni oldindan tanlashimiz mumkin",
        FlightInsurance: "Parvoz sug'urtasi",
        FlightDelayAndLostLuggage: "Bekor qilish, parvozni kechiktirish va yo'qolgan yuk",
        Notifications: "Bildirishnomalar",
        CancellationaSiaSMSAndEmail: "SMS va elektron pochta orqali parvozni o'zgartirish yoki bekor qilish",
        TicketReturn: "Chiptani qaytarish",
        ReasonForReturnCanBeAny: "Qaytarishning sababi har qanday bo'lishi mumkin",
        OnlineCheckInForFlight: "Parvoz uchun onlayn ro'yxatdan o'tish",
        CheckYouInForFlight: "Sizni parvozga tekshiring",
        BuyMoreLuggage: "Ko'proq yuk sotib oling",
        ForOneOrAllPassengers: "Bir yoki barcha yo'lovchilar uchun",
        AllInclusive: "Hammasi o'z ichiga",
        IncludesFlightInsuranceService: "Parvoz sug'urtasi, bildirishnomalar, OneTwoTrip xizmati to'lovidan voz kechishni o'z ichiga oladi."
    },
    footer: {
        company: "Kompaniya",
        oCompany: "Kompaniya haqida",
        Flights: "Aviachipta",
        Information: "Ma'lumot",
        Offer: "Taklif",
        Confidentiality: "Mahfiylik",
        social: "Biz ijtimoiy tarmoqlardamiz",
        Download: "Ma'lumotni yuklash",
        sell: "Toshkentda aviachiptalarni onlayn sotish",
        legal: "Toshkent shahri, Chilonzor, Qatartol-8. Yuridik shaxs sifatida ro'yxatdan o'tgan \"Avione\" xususiy korxonasi O‘zbekiston Respublikasi qonun hujjatlariga muvofiq shaxslar 05.03.2020, Ro‘yxatdan o‘tganlik guvohnomasi No824437"
    },
    filter_panel: {
        Top: "Yuqori filtr",
        luggage: "bagaji bilan",
        noChange: "Aeroportda o'zgarish yo'q",
        Direct: "To'g'ridan-to'g'ri",
        CheapFirst: "Avval arzonlari",
        DearOnesFirst: "Avval qimmatlari",
        ByDuration: "Davomiyligi bo'yicha",
        EarlyDeparture: "Erta ketish",
        LateCheckOut: "Kech chiqish",
        Airlines: "Aviakompaniyalar",
        FilterCleaning: "Filtrni tozalash",
        Filter: "Filtr"
    },
    login: {
        account: "Shaxsiy hisobingizga kiring",
        phoneNumber: "Telefon raqami",
        Agree: "Men shartlarni o‘qib chiqdim va roziman",
        publicOffer: "Ommaviy taklif",
        privacyPolicy: "Maxfiylik siyosati",
        comeIn: "Kirish uchun",
        EnterCode: "Raqamingizga yuborgan kodni kiriting",
        Code: "Kodni qayta yuboring",
        EnterActivationCode: "Faollashtirish kodini kiriting",
        Proceed: "Davom eting",
        Register: "Roʻyxatdan oʻtish",
        Profile: "Profil",
        Exit: "Chiqish"
    },
    passenger: {
        Surname: "Familiya",
        Name: "Ism",
        MiddleName: "Otasining ismi",
        DateOfBirth: "Tug'ilgan kun",
        Floor: "Jinsi",
        request: "Biz sizning so'rovingiz uchun eng yaxshi variantlarni qidirmoqdamiz",
        PassengerData: "Yo'lovchi ma'lumotlari",
        Ticket: "Chipta",
        Adult: "voyaga yetgan",
        Children: "bolalar uchun",
        ChildrenDiscount: "5 yoshdan 10 yoshgacha bo'lgan bolalar uchun chegirma mavjud",
        Child: "5 yoshgacha bo'lgan bola uchun (o'rindiqsiz)",
        AddChild: "5 yoshgacha bo'lgan bola qo'shish",
        RemoveChild: "Bola uchun o'rindiqni olib tashlash",
        EnterYourPersonalDetail: "Siz uchadigan hujjatda ko'rsatilganidek, shaxsiy ma'lumotlarni kiriting. Biz avtomatik ravishda matnni lotin tiliga tarjima qilamiz - xavotir olmang, hammasi yaxshi",
        PassengerType: "Yo'lovchi turi",
        EnterPassengersLastName: "Yo'lovchining familiyasini kiriting",
        Email: "Elektron pochta",
        DocumentType: "Hujjat turi",
        Region: "Hudud",
        DocumentNumber: "Hujjat raqami",
        PassportIssueDate: "Pasport berilgan sana",
        PassportExpirationDate: "Pasportning amal qilish muddati",
        Citizenship: "Fuqarolik",
        FullName: "To'liq ism",
        Validity: "Amal qilish muddati",
        NewDocument: "Yangi hujjat",
        Phone: "Telefon",
        man: "Erkak",
        woman: "Ayol.",
        Another: "Boshqa",
        Cancel: "Bekor qilish",
        Save: "Saqlash",
        total: "Jami",
        singular: 'yo\'lovchi',
        plural: 'yo\'lovchilar',
        selectPassengerName: "Passenger nomini tanlang",
        type: {
            [PassengerType.Adult]: 'Kattalar',
            [PassengerType.Kid]: 'Bolalar',
            [PassengerType.Baby]: 'Chaqaloqlar',
        },
        age: {
            [PassengerType.Adult]: '12 yoshdan yuqori',
            [PassengerType.Kid]: '2-11',
            [PassengerType.Baby]: '0-1',
        }
    },
    AvioneMessage: {
        PAY_LATER: "Keyinroq",
        CONTINUE: "Davom etish",
        PAYMENT_BOOKING_VOID_FAIL: "Afsuski, chiptangizni bekor qila olmadik. Iltimos keyinroq qayta urinib ko'ring.",
        TICKET_VOIDING: "Bronlangan chiptani bekor qilish...",
        PAYMENT_BOOKING_VOID_SUCCESS: "Chiptangiz muvaffaqiyatli bekor qilindi.",
        TICKET_PRICE_CHANGED: "Chipta narxi o'zgardi, davom etishni va yangi narxda to'lashni xohlaysizmi?",
        TICKET_PRICE_CHANGED_TITLE: "Chipta narxi o'zgardi",
        PHONE_NUMBER_REQUIRED: 'Telefon raqam majburiy',
        SELECT_DEPARTURE_DATE: 'Iltimos, Parvoz sanasini tanlang',
        SELECT_FLIGHT_LOCATION: "Iltimos, Parvoz joyini tanlang",
        PAYMENT_STATUS_SUCCESS: "Chipta muvaffaqiyatli chiqarildi",
        PAYMENT_STATUS_CHECKING_DISCHARGE: "Iltimos, kuting, chipta chiqarilmoqda...",
        discharged: "Bo'shatilgan",
        eneterMiddleNamePassenger: "Yo'lovchining otasining ismini kiriting",
        bookingTicketNotFound: "Buyurtma uchun chipta topilmadi",
        sureToCancelBooking: "Buyrtmani bekor qilishga ishonchingiz komilmi?",
        WrongEmail: "Iltimos emailni kiriting",
        WrongPassportIssue: "Iltimos passport bersilgan sanani kiriting",
        WrongPassportExpire: "Iltimos passport amal qilish muddatini kiriting",
        unable: "Telefon raqamingiz formatini aniqlab bo'lmadi",
        selectRate: "Narxni tanlang",
        loginAgain: "Iltimos, avtorizatsiyadan o'ting va qaytadan urinib ko'ring",
        EnterPassengerName: "Yo'lovchi nomini kiriting",
        EnterPassengersLastName: "Yo'lovchining familiyasini kiriting",
        EnterPassengersDateOfBirth: "Yo'lovchining tug'ilgan sanasini kiriting",
        ChooseGender: "Jinsni tanlang",
        SelectNationality: "Millatni tanlang",
        EnterPassengersPhoneNumber: "Yo'lovchining telefon raqamini kiriting",
        SelectDocumentType: "Hujjat turini tanlang",
        EnterDocumentNumber: "Hujjat raqamini kiriting",
        WrongPhoneNumber: "Telefon raqami noto‘g‘ri",
        PleaseSelectPaymentMethod: "Toʻlov usulini tanlang",
        WeWillRedirectYouPaymoPageForPayment: "Toʻlov uchun sizni Payme sahifasiga yoʻnaltiramiz",
        WeWillRedirectYouAvipayPageForPayment: "Toʻlov uchun sizni Avipay sahifasiga yoʻnaltiramiz",
        CheckingBillPayments: "Toʻlovlar tekshirilmoqda...",
        verifyYourPayments: "Toʻlovlaringizni tasdiqlay olmadik. Siz bron qilingan chipta muddati tugashidan oldin to'lashingiz mumkin",
        PleaseWait: "Iltimos kuting...",
        OrderNotFound: "Buyurtma topilmadi",
        WrongCode: "Noto'g'ri kod",
        PleaseEnterSMSCode: "Iltimos, biz yuborgan SMS kodni kiriting",
        AllFieldsRequired: "Barcha maydonlarni kiritish shart!",
        WeWereUnable: "Buyurtmangizni oʻchirib boʻlmadi, keyinroq qayta urinib koʻring.",
        PleaseLogIn: "Iltimos, bandlovni davom ettirish uchun tizimga kiring.",
        AreYouSureYouCancelBourBooking: "Haqiqatan ham bandlovni bekor qilmoqchimisiz?",
        // YourTicketHasBeenSuccessfullyCancelled:"Chiptangiz muvaffaqiyatli bekor qilindi.",
        DownloadTicket: "Chipta yuklab olish",
        NotEnoughInBalance: 'Balansingizda pul yetarli emas'
    },
    PersonalArea: {
        PersonalArea: "Shaxsiy kabinet",
        Orders: "Buyurtmalar",
        Balans: "Hisob",
        MyOrders: "Mening buyurtmalarim",
        MyVoid: 'Bekor qilinganlar',
        MyBookings: "Mening bandlovlarim",
        GoOut: "Chiqish",
        Notebook: "Daftar"
    },
    CheckAviabilty: {
        AvailabilityCheck: "Mavjudligini tekshirish",
        TariffAvailabilityCheck: "Tarif mavjudligini tekshirish",
        PleaseWaitBookingInProgress: "Iltimos, kuting, bandlov davom etmoqda..."
    },
    flight_detail: {
        TheCheapest: "Eng arzon",
        FlightDetails: "Parvoz tafsilotlari",
        CollapseDetails: "Tafsilotlarni yig'ish",
        WaitingForDischarge: "Bo'shatishni kutish",
        Canceled: "Bekor qilingan",
        Discharged: "Bo'shatilgan",
        AreYouSureYouantDelete: "Haqiqatan ham oʻchirib tashlamoqchimisiz?",
        TimeLeftPay: "To'lash uchun vaqt qoldi",
        PNRNumber: "PNR raqami",
        OrderNumber: "Buyurtma raqami",
        AmountPayable: "To'lanishi kerak bo'lgan miqdor",
        TermsOfReturnExchange: "Qaytish va almashtirish shartlari",
        Cancellation: "Bekor qilish",
        PayForTOrder: "Buyurtma uchun to'lov",
        Order: 'Buyurtma'
    },
    payment_type: {
        SelectPaymentMethod: "To'lov usulini tanlang",
        TicketsIssuedSuccessfully: "Chiptalar muvaffaqiyatli chiqarildi!",
        ThankYouForUsingAvione: "Thank you for using Avione.",
        ToTheMain: "Asosiyga",
        ThroughPaymentSystem: "to'lov tizimi orqali",
        RemoveFromBalance: 'Balansdan yechish',
        UseMixedPayment: "Mablag' yetarli emas, iltimos, “aralash to'lov” dan foydalaning",
        MixedPayment: "Aralash to'lov",
        HowMuchFromBalance: "Balansdan qancha summa yechishni hoxlaysiz?",
        Left: 'Qolgan',
        WillBePaidThroughPaymentSystem: "sum to'lov tizimi orqali to'lanadi"
    },
    price_change_popup: {
        TicketPriceHasChanged: "Chipta narxi o'zgardi",
        OldPrice: "Eski narx",
        NewPrice: "- Yangi narx",
        TheTicketPriceHasChanged: "Chipta narxi o'zgardi, davom etishni va yangi narxda to'lashni xohlaysizmi?",
        Later: "Keyinchalik",
        Continue: "Davom eting"
    },
    balance_replanish_popup: {
        ReplanishBalance: "Balansni to'ldirish",
        EnterReplenishmentAmount: "To'ldirish miqdorini kiriting",
        Replanish: "To'ldirish"
    },
    steps: {
        Booking: "1. Buyurtma",
        Payment: "2. To'lov",
        GettingTicket: "3. Chipta olish",
        TicketBooking: "Chipta bron qilish",
        Payments: "To'lov",
        PaymentStatus: "To'lov holati",
        AirlineFares: "Aviakompaniya tariflari",
        TashkentDubai: "Toshkent - Dubay"
    },
    Basic: {
        Cancel: "Bekor qilish",
        ToBook: "Buyurtma berish",
        GoToThePayment: "To'lovga o'ting",
        DownloadTicket: 'Chipta yuklab olish'
    },
    empty_result: {
        Filtr: "Filtr",
        CheapFirst: "Avval arzon",
        DearOnesFirst: "Avvalo azizlar",
        MostWanted: "Eng qidirilayotgan",
        TopFilter: "Yuqori filtr",
        WithLuggage: "Bagaj bilan",
        NoAirportChange: "Aeroportda o'zgarish yo'q",
        Direct: "To'g'ridan-to'g'ri",
        FlightDetails: "Parvoz tafsilotlari",
        Buy: "Sotib olish"
    },
    avione_ads: {
        BigCashbackWallet: "Big Cashback Wallet har bir xarid uchun 2% cashback.",
        ToLearnMore: "Qo'shimcha ma'lumot olish uchun",
        FindOutAboutUnusualTravel: "Noodatiy sayohatlar haqida &nbsp; bizning &nbsp; Telegram orqali bilib oling.",
        WeHaveCreated: "Biz 24/7&nbsp; koll-markazni yaratdik, u yerda savollar &nbsp; va&nbsp; so'rovlarni telefon orqali yuborishingiz mumkin."
    },
    location_history: {
        SearchHistory: "Qidiruv tarixi",
        ShowMore: "Ko'proq ko'rsatish",
        regionNijniy: "Nijniy Novgorod (GOJ) – Sheremetyevo (SVO)",
        EconomyClass: "26 aprel, 1 kattalar, iqtisod sinfi",
        regionTashkent: "Toshkent (TAS) – Sheremetyevo (SVO)"
    },
    privacy: {
        AvionePrivacyPolicy: "Avione Maxfiylik siyosati",
        AboutUs: "Biz haqimizda",
        privatelyOwnedCompany: "Частное предприятие Avione, очень серьезно относятся к Вашей конфиденциальности. Настоящая Политика\n" +
            "          конфиденциальности разработана с учетом Ваших потребностей, и важно, чтобы Вы имели полное представление о\n" +
            "          нашей практике сбора и использования личной информации, позволяя Вам при этом полностью контролировать личную\n" +
            "          информацию, которую Вы предоставляете Avione.",
        OnTermsOfThisAgreement: "На условиях настоящего Соглашения об обработке персональных данных (далее - Соглашение), Частное предприятие\n" +
            "          «Avione», зарегистрированному в качестве юридического лица по законодательству Республики Узбекистан\n" +
            "          05.03.2020 г., Свидетельство о регистрации №824437, почтовый адрес которого находится: Республика Узбекистан,\n" +
            "          г.Тошкент, Чилонзор, Катартал-8, Катортол (далее – «Агент») предоставляет пользователям приложения Avione,\n" +
            "          расположенного по адресу https://play.google.com/store/apps/details?id=com.avione.uz, право пользования\n" +
            "          приложения, при помощи которых Пользователи могут осуществить поиск авиабилетов и прочих услуг и предложений\n" +
            "          на рынке авиабилетов.",
        AboutThisPrivacyPolicy: "О настоящей Политике конфиденциальности",
        ThePrivacyPolicyAppliesAllAvioneDevices: "Политика конфиденциальности применяется ко всем устройствам, веб-сайтам и приложениям Avione, которые\n" +
            "          упоминаются или ссылаются на настоящую Политику конфиденциальности. Настоящая Политика конфиденциальности\n" +
            "          объясняет, как Avione собирает, использует, раскрывает, обрабатывает и защищает любую личную информацию,\n" +
            "          которую Вы нам предоставляете во время использования наших продуктов и услуг, доступ к которым Вы можете\n" +
            "          получить через веб-сайт https://www.avione.uz и наши приложения, которые мы предоставляем на наших мобильных\n" +
            "          устройствах. Мы будем использовать Вашу личную информацию строго в соответствии с настоящей Политикой\n" +
            "          конфиденциальности. Если этого требует контекст, личная информация также должна включать в себя\n" +
            "          конфиденциальные личные данные или информацию, которая может быть отнесена к категории в соответствии с\n" +
            "          применимым правом.",
        TheAvioneApplicationSellsAndBook: "Важное: приложения Avione осуществляет непосредственную продажу и бронирование авиабилетов пользователям.\n" +
            "          Администратор не выступает в качестве Поставщика услуг. Администратор со своей стороны обязуется делать все\n" +
            "          возможное для решения проблем пользователей, возникших при использовании приложения Avione, в том числе\n" +
            "          оказать пользователям помощь во взаимодействии с Поставщиком услуг.",
        thisDocumentWithAllAdditions: "Соглашение – настоящий документ со всеми дополнениями, изменениями и указанными в нем обязательными\n" +
            "          документами, а также договор, заключенный между Avione и Пользователем на его основании",
        onlineServiceAndroidAddress: "Приложения – онлайн-сервис по адресу Android: https://play.google.com/store/apps/details?id=com.avione.uz для\n" +
            "          поиска информации об авиабилетах, бронировании и приобретении авиабилетов, а также для облегчения\n" +
            "          (автоматизации) процесса их приобретения.",
        capableNaturalPerson: "Пользователь – дееспособное физическое лицо, достигшее 18 лет, имеющее законное право вступать в договорные\n" +
            "          отношения с Поставщиком услуг, в соответствии с 3 Главой Гражданского Кодекса Республики Узбекистан, способный\n" +
            "          в том числе совершать Заказ или бронировать услуги на приложения Avione, исключительно для личных нужд, не\n" +
            "          связанных с осуществлением предпринимательской деятельности.",
        PersonalDataAnyInformationThat: "Персональные данные – любая информация, которую Пользователь предоставляет о себе самостоятельно при\n" +
            "          регистрации на приложения Avione, а также в процессе дальнейшего использования приложения Avione. К такой\n" +
            "          информации, в частности, следует относить: Ваше Имя, Фамилия, номер мобильного телефона, адрес электронной\n" +
            "          почты, Дата Рождения, Номер документа, Срок действия паспорта, Дата выдачи паспорта, Гражданство, Пол, а также\n" +
            "          другую информацию, включая ряд технических данных: IP адрес, историю посещений приложения и другие.",
        PersonalAccountPersonalSection: "Личный кабинет — персональный раздел приложения, к которому Пользователь получает доступ после прохождения\n" +
            "          регистрации и/или авторизации на приложения. Личный кабинет предназначен для хранения персональных данных\n" +
            "          Пользователя (личные данные и документы), оформление Заказов, просмотр информации о совершенных Заказах,\n" +
            "          стадии их выполнения, текущее состояние выставленных счетов, и получения уведомлений.",
        TheUserWhenClickingOn: "Пользователь при нажатии на текст Соглашение об использовании персональных данных на форме авторизации, может\n" +
            "          ознакомится с информацией об условиях сбора и хранения Персональных данных.",
        WhenRegisteringTheUser: "При регистрации Пользователь самостоятельно, свободно, своей волей и в своих интересах дает Администратору\n" +
            "          бессрочное письменное согласие на любые способы обработки своих персональных данных.",
        TheUserMustIndependentlyFamiliarize: " Пользователь самостоятельно должен ознакомиться с условиями использования его персональных данных до начала их\n" +
            "          обработки. Приложения прекращает обработку Персональных данных и уничтожает их сразу после удаления учетной\n" +
            "          записи Пользователя. В этом случае Администратор сохраняет только обезличенные персональные данные\n" +
            "          Пользователя в своих системах баз данных.",
        TheAdministratorIsNotResponsible: "Администратор не отвечает за полноту и актуальность сведений о Пользователях. Пользователь вправе в любой\n" +
            "          момент самостоятельно отредактировать в своем Аккаунте предоставленные им при регистрации или авторизации\n" +
            "          Персональные данные.",
        HowCanWeHelpYou: "Как мы можем помочь Вам",
        UltimatelyWeStriveTo: "В конечном итоге, мы стремимся предоставить наилучшие условия для всех наших пользователей. Если у Вас\n" +
            "          возникнут вопросы о наших методах обработки данных, описанных в настоящей Политике конфиденциальности, Вы\n" +
            "          можете перейти по адресу https://www.avione.uz/support для решения конкретных проблем. Мы будем рады помочь\n" +
            "          Вам.",
        WhatKindOfInformation: "1. Biz qanday ma'lumotlarni yig'amiz va undan qanday foydalanamiz",
        WhatKindOfInformationcollect: "1.1. Biz qanday ma'lumotlarni yig'amiz",
        InOrderProvideYouWithOur: "Для предоставления Вам наших услуг мы попросим Вас предоставить личную информацию, необходимую для\n" +
            "          предоставления Вам этих услуг. Мы будем осуществлять сбор только той информации, которая необходима для\n" +
            "          указанных, конкретных, явных и законных целей, и будем следить за тем, чтобы такая информация не\n" +
            "          обрабатывалась в дальнейшем способом, несовместимым с этими целями. У Вас есть право решать, предоставлять ли\n" +
            "          запрошенную нами информацию или нет, но в большинстве случаев, если Вы не предоставите свою личную информацию,\n" +
            "          мы не сможем предоставить Вам наши продукты или услуги или ответить на Ваши запросы.\n" +
            "          Приложения со своей стороны синхронизирует историю поиска, настройки и избранное в личном аккаунте\n" +
            "          Пользователя.",
        InformationYouProvideToUs: "1.1.1. Информация, которую Вы нам предоставляете\n" +
            "          Чтобы использовать наше приложение, вы должны пройти нашу регистрацию.\n" +
            "          Чтобы зарегистрироваться в нашей системе, Вам необходимо предоставить Ваше Имя, Фамилия, номер мобильного\n" +
            "          телефона, адрес электронной почты, Дата Рождения, Номер документа, Срок действия паспорта, Дата выдачи\n" +
            "          паспорта, Гражданство, Пол. Чтобы забронировать билет в нашей системе Вам необходимо предоставить информация о\n" +
            "          вашем пассажире Имя, Фамилия, номер мобильного телефона, адрес электронной почты, Дата Рождения, Номер\n" +
            "          документа, Срок действия паспорта, Дата выдачи паспорта, Гражданство, Пол.",
        InformationCollectedWhenYou: "1.1.2. Информация, собираемая во время использования Вами нашими службами\n" +
            "          Администратор может использовать трансграничную передачу персональных данных на территорию иностранного\n" +
            "          государства иностранному юридическому лицу в целях исполнения договора с Пользователем. При достижении всех\n" +
            "          поставленных целей Администратор может гарантировать Пользователю уничтожение его Персональных данных на\n" +
            "          территории иностранного государства.",
        NonIdentifyingInformation: "1.1.3. Неидентифицирующая информация\n" +
            "          Мы также можем осуществлять сбор других типов информации, которая не связана прямо или косвенно с человеком и\n" +
            "          которая может быть определена как личная информация в соответствии с применимым местным законодательством.\n" +
            "          Такая информация называется неидентифицирующей. Мы можем собирать, использовать, передавать и раскрывать\n" +
            "          неидентифицирующую информацию. Ниже приведены несколько примеров информации, которую мы собираем, а также\n" +
            "          способы ее использования в агрегированном неидентифицирующем формате:",
        WeMayIncludeStatistical: "Мы можем включать статистические данные, которые генерируются, когда Вы используете Avione (например,\n" +
            "              неидентифицирующую информацию об устройстве, события ежедневного использования, события доступа к\n" +
            "              страницам).",
        NetworkNonitoringData: "Данные мониторинга сети (например, время запроса, количество запросов и запросов об ошибках и т. д.).",
        ApplicationFailureEvents: "События сбоев работы приложений (например, автоматическое создание журналов после обнаружения сбоев в\n" +
            "              работе приложений и т. д.).",
        ThePurposeOfThisCollectionOf: "Целью такого сбора информации является улучшение качества предоставляемых Вам услуг. Тип и объем\n" +
            "              собираемой информации зависит от того, как Вы используете наши продукты и/или услуги.",
        WeSummarizeSuchInformationIn: "Мы суммируем такую информацию, чтобы предоставлять Вам наиболее полезную информацию и понять, какие части\n" +
            "              наших приложений, веб-сайтов Вам наиболее интересны. Например, нам может потребоваться только количество\n" +
            "              активных пользователей за день. Но нам не нужно знать, кто конкретно был активен в конкретный день.\n" +
            "              Поэтому для статистического анализа достаточно агрегированных данных. Мы постараемся изолировать Ваши\n" +
            "              личные данные от неидентифицирующей информации и обеспечим раздельное использование этих двух типов\n" +
            "              данных. Однако, если мы объединяем неидентифицирующую информацию с личной информацией, такая объединенная\n" +
            "              информация будет рассматриваться как личная информация до тех пор, пока она остается объединенной.",
        HowWeUseThePersonal: "2. Как мы используем собранную нами личную информацию",
        ThePurposeOfCollectingYour: "Целью сбора вашей личной информации является аутентификация пользователей нашего приложения и идентификация их\n" +
            "          забронированных, оплаченных билетов и истории их пассажиров.",
        ProvidingProcessingMaintaining: "Предоставление, обработку, обслуживание, улучшение и развитие наших продуктов и/или услуг для Вас.",
        PromotionOfTheAvioneApp: "Продвижение приложения Avione на рынке путем осуществления прямых контактов с Пользователями с помощью\n" +
            "              сетей связи",
        InternalPurposesSuchAs: "Внутренние цели, такие как анализ данных, исследование и разработка статистической информации, связанной с\n" +
            "              использованием наших продуктов и услуг для последующего улучшения их качества. Например, машинное обучение\n" +
            "              или модель обучения алгоритмов выполняется после деидентификации.",
        OptimizingOurProductsAnd: " Оптимизация наших продуктов и/или услуг(приложения Avione и веб-сайт https://www.avione.uz).",
        StoringAndMaintainingInformation: "Хранение и обслуживание связанной с Вами информации для наших деловых операций или для выполнения наших\n" +
            "              юридических обязательств.",
        HowWeShareTransfer: "3. Как мы делимся, передаем и публично раскрываем Вашу личную информацию",
        Sharing: "3.1. Совместное использование",
        WeDoNotSellYourPersonal: "Мы не продаем Вашу личную информацию сторонним лицам.",
        WeMayOccasionally: "Иногда мы можем передавать Вашу личную информацию сторонним лицам (как описано ниже) для предоставления или\n" +
            "          улучшения качества наших продуктов и услуг, включая предложение продуктов или услуг на основе Ваших\n" +
            "          требований. Дополнительная информация о совместном использовании данных изложена ниже.",
        SharingInformationAbout: "3.1.1. Передача сведений о том, что Вы активно выбираете или запрашиваете\n" +
            "          С Вашего согласия или по Вашему запросу мы поделимся Вашей личной информацией в рамках Вашего согласия/запроса\n" +
            "          с конкретными третьими лицами, обозначенными Вами.",
        SharingWithThirdParty: "3.1.2. Совместное использование со сторонними поставщиками услуг и деловыми партнерами\n" +
            "          Чтобы помочь нам предоставить Вам продукты и услуги, описанные в настоящей Политике конфиденциальности, мы\n" +
            "          можем при необходимости передавать Вашу личную информацию нашим сторонним поставщикам услуг и деловым\n" +
            "          партнерам.\n" +
            "          К ним относятся наши поставщики услуг доставки, центры обработки данных, хранилища данных, поставщики услуг\n" +
            "          для клиентов, поставщики рекламных и маркетинговых услуг и другие деловые партнеры.\n" +
            "          Мы стремимся к тому, чтобы передача личной информации, необходимой для предоставления Вам услуг,\n" +
            "          осуществлялась исключительно в легальных, законных, необходимых, конкретных и явных целях. Avione будет\n" +
            "          проводить комплексную проверку и заключать контракты, чтобы гарантировать, что сторонние поставщики услуг\n" +
            "          соблюдают применимые законы о конфиденциальности в Вашей юрисдикции.",
        ToProvidePerformanceMeasurement: "Для обеспечения измерения производительности, анализа и других бизнес-услуг мы также можем делиться\n" +
            "          информацией (неличной информацией) с третьими сторонами (такими как рекламодатели на наших веб-сайтах) в\n" +
            "          обобщенном виде. Мы используем имеющуюся у нас информацию для обеспечения помощи рекламодателям и другим\n" +
            "          деловым партнерам в оценке эффективности и охвата их рекламы и услуг, а также для понимания типов людей,\n" +
            "          которые используют их услуги, и того, как люди взаимодействуют с их веб-сайтами, приложениями и службами. Мы\n" +
            "          также можем делиться с ними общими тенденциями использования наших услуг, такими как количество клиентов в\n" +
            "          определенной группе людей",
        Broadcast: "3.2. Передача",
        AvioneWillNotShareYour: "Avione не будет передавать Вашу информацию какому-либо субъекту, за исключением следующих случаев:",
        IfWeHaveObtained: "Если мы получили Ваше явное согласие.",
        AtTheRequestOfCourt: "По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством\n" +
            "              процедуры",
        UnderTheCircumstancesDescribed: "При обстоятельствах, описанных в настоящей Политике конфиденциальности или в любом другом уведомлении.",
        ToProtectTheRightsAndLegitimate: "Для защиты прав и законных интересов Администратора в связи с нарушением заключенных с Пользователем\n" +
            "              договоров",
        PublicDisclosure: "3.3. Публичное раскрытие",
        AvioneMayPublicly: "Avione может публично раскрыть Вашу личную информацию при следующих обстоятельствах:",
        WhenWeNeedAnnounce: "• Когда нам нужно объявить победителя рекламной акции, конкурса или розыгрыша. В этом случае мы публикуем\n" +
            "              только ограниченную информацию.",
        IfWeHaveObtainedYour: "• Если мы получили Ваше явное согласие или если Вы раскрыли информацию через наши службы, например, на\n" +
            "              страницах в социальных сетях или на общедоступных форумах. Или",
        PublicDisclosureOfInformation: "• Публичное раскрытие информации на основании закона или уместных оснований: включая законы и\n" +
            "              постановления, юридические процедуры, судебные разбирательства или по запросу компетентных государственных\n" +
            "              ведомств.",
        Security: "4. Безопасность",
        TheAdministratorStoresPersonal: "Администратор хранит Персональные данные и обеспечивает их охрану от несанкционированного доступа и\n" +
            "          распространения в соответствии с внутренними правилами и регламентами. В отношении Персональных данных\n" +
            "          Пользователя сохраняется их конфиденциальность, за исключением случаев, когда технология Приложения либо\n" +
            "          настройки используемого Пользователем программного обеспечения предусматривают открытый обмен информацией с\n" +
            "          иными Пользователями приложения или с любыми пользователями сети Интернет.",
        PrivacyPolicyAndUse: "5. Политика конфиденциальности и использование персональной информации",
        ByUsingTheAvioneApps: "Используя приложения Avione, Вы подтверждаете, что Вы ознакомлены и согласны со всеми положениями и условиями,\n" +
            "          а также с политикой конфиденциальности, которая включает в себя использование персональной информации.\n" +
            "          Соглашение может быть изменено Администратором в одностороннем порядке без предварительного уведомления\n" +
            "          Пользователя. Новая редакция Соглашения вступает в силу с момента ее размещения по адресу\n" +
            "          https://test-frontend.avione.uz/privacy\n" +
            "          При внесении изменений в актуальной редакции указывается дата последнего обновления.",
        FinalProvisions: "6. Заключительные положения",
        TheAgreementIsGovernedBy: "Cоглашение регулируется и толкуется в соответствии с Республики Узбекистан и является действующим для всех\n" +
            "          Пользователей. Все вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с\n" +
            "          законодательством Республики Узбекистан."
    },

    RailwayTariff: {
        ChooseCarriageAndSeat: "Vagon va joyni tanlang",
        Price: "Narxi:",
        Carriage: "Vagon:",
        ChooseUpToFourSeat: "4 tagacha joy tanlang:",
        FreePlaces: "Bo'sh joylar",
        ChoosenCarriages: "Tanlangan vagon",
        AirConditioner: "konditsioner",
        SelectedSeats: "Tanlangan joylar",
        TicketCost: "Chipta narxi",
        ServiceCost: "Xizmat narxi",
        TotalAmount: "Umumiy qiymat"
    },

    Yes: "Bor",
    No: "Yo'q",

    Currency: "so'm",
    "not required": 'majburiy emas'
}
