
import {defineComponent, PropType} from "vue";
import {Segment, Ticket} from "@/models/flight_models";
import {OrderData} from "@/models/stored_data_models";
import {LocalDb} from "@/repository/localdb";

export default defineComponent({
  name: "FlightPaymentExtraInfo",
  props: {
    'ticket': {type: Object as PropType<OrderData>}
  },
  data() {
    return {
      orderData: {} as OrderData | undefined,
      segment: {} as Segment | undefined
    }
  },
  created() {
    if (this.ticket) {
      if (this.ticket.flightDetails.departure && this.ticket.flightDetails.departure.length > 0) {
        this.segment = this.ticket.flightDetails.departure[0];
      } else {
        this.segment = this.ticket.flightDetails.arrival;
      }
    }
    const pnrNumber = this.$route.params.id;
    if (pnrNumber) {
      const order: OrderData | undefined = LocalDb.getMyFlightOrder(pnrNumber.toString());
      if (order) {
        this.orderData = order;
      } else {
        window.location.href = '/';
      }
    }
  },


});
