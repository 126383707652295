
import {computed, defineComponent} from "vue";
import TheHeader from "@/components/TheHeader.vue";
import NavigatorLine from "@/components/NavigatorLine.vue";
import TheFooter from "@/components/TheFooter.vue";
import LeftMenu from "@/components/Profile/LeftMenu.vue";
import FlightPassengersList from "@/components/Profile/FlightPassengersList.vue";
import {FlightSearchResult} from "@/models/flight_models";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {useHead} from "@vueuse/head";

export default defineComponent({
  name: "FlightPassengers",
  data() {
    return {
      loading: false,
      isLoginOpen: false,
      searchResult: {} as FlightSearchResult,
    }
  },
  components: {
    TheHeader,
    NavigatorLine,
    LeftMenu,
    FlightPassengersList,
    TheFooter,
  },
  setup() {
    const {t} = useI18n({useScope: 'global'});
    const route = useRoute()
    const router = useRouter()
    const currentUrl = computed(() => router.resolve({
      name: 'FlightPassengers',
      params: {lang: route.params.lang}
    }).href)

    useHead({
      title: computed(() => t('pages.home.title')),
      meta: [
        {
          name: `description`,
          content: computed(() => t('pages.home.description')),
        },
        {
          name: `og:title`,
          content: computed(() => t('pages.home.title')),
        },
        {
          name: `og:description`,
          content: computed(() => t('pages.home.description')),
        },
        {
          name: `og:type`,
          content: 'website',
        },
        {
          name: `og:url`,
          content: computed(() => `https://avione.uz${currentUrl.value}`),
        },
      ],

      link: computed(() => [
        {
          rel: 'canonical',
          href: `https://avione.uz${currentUrl.value}`
        }
      ].concat([
        {lang: 'uz', prefix: 'uz'},
        {lang: 'en', prefix: 'en'},
        {lang: 'ru', prefix: ''},
      ].filter((l) => route.params.lang !== l.prefix).map((l) => {
        return {
          rel: 'alternate',
          hreflang: l.lang,
          href: 'https://avione.uz' + router.resolve({
            name: 'Home',
            params: {lang: l.prefix}
          }).href
        }
      })))
    })
  },
});
